import React, { useLayoutEffect, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import moment from "moment";
import ContentHeader from "../common/contentHeader";
import { Row } from "react-bootstrap";
import Input from "../common/input.jsx";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Cuenta from "../common/cuenta";
import Report from "../common/Report/Report";
import Records from "../common/records.jsx";
import Pagination from "../common/pagination.jsx";
import FileBrowser from "../fileBrowser/fileBrowser";
import { pdf, PDFDownloadLink, usePDF } from "@react-pdf/renderer";
import { getUser } from "../../services/userService";
import { getRubros } from "../../services/categoriasService";
import { paginate } from "../utils/paginate";
import { getProyecto } from "../../services/proyectosService";
import { getPermisos } from "../../services/permisosService";
import { getCajaSetup, deleteCajaRecord, getCajas, saveCajaRecord, getNextAporte, getAportesPendientes, getNextCuota } from "../../services/cajasDomoService";
import { dateFormatter, formattedToFloat, numberWithCommas, formasPago } from "../utils/utils";
import { toastBigError, toastConfirm, toastInfo } from "../utils/toasts";
import { getConfig } from "../../config/config.js";
import { isEmpty } from "lodash";

import "./cajasdomo.css";
import Comprobante from "../common/comprobante.jsx";

export default function CajasDomo(props) {
  const title = "Cajas";

  const menuLink = "/cajasdomo";

  const breadcrumbs = [
    {
      title: "Inicio",
      link: "/main",
    },
    {
      title: "Proyectos",
      link: "/proyectos",
    },
    {
      title: "Cajas",
    },
  ];

  let initialReportData = {
    title: "Título del Reporte",
    columns: [
      { header: "Fecha", width: "10%", styles: {} },
      { header: "Proyecto/Socio", width: "20%", styles: {} },
      { header: "Rubro", width: "15%", styles: {} },
      { header: "Detalle", width: "15%", styles: {} },
      { header: "Ingreso", width: "10%", styles: { flex: 1, textAlign: "center" } },
      { header: "Egreso", width: "10%", styles: { flex: 1, textAlign: "center" } },
      { header: "Saldo", width: "10%", styles: { flex: 1, textAlign: "center" } },
    ],
    items: [],
    itemStyles: [{}, {}, {}, {}, { flex: 1, textAlign: "right" }, { flex: 1, textAlign: "right" }, { flex: 1, textAlign: "right" }],
  };

  const customStyles = {
    groupHeading: (provided, state) => ({
      ...provided,
      fontWeight: "bold",
      color: "black",
      padding: 10,
    }),
  };

  let componentRef = "";
  const config = getConfig();
  const [mounted, setMounted] = useState(false);
  const [desde, setDesde] = useState(null);
  const [permisos, setPermisos] = useState();
  const [permisosOriginal, setPermisosOriginal] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [status, setStatus] = useState("");
  const [newRecord, setNewRecord] = useState({});
  const [originalRecord, setOriginalRecord] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  const [cajas, setCajas] = useState([]);
  // const [fullCajas, setFullCajas] = useState([]);
  const [cuentas, setCuentas] = useState([]);
  const [monedas, setMonedas] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [rubros, setRubros] = useState([]);
  const [categoriasOriginal, setCategoriasOriginal] = useState([]);
  const [destinos, setDestinos] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [proyectosOriginal, setProyectosOriginal] = useState([]);
  const [presupuestos, setPresupuestos] = useState([]);
  const [personas, setPersonas] = useState([]);
  const [reportData, setReportData] = useState(initialReportData);
  const [selectedMoneda, setSelectedMoneda] = useState({});
  const [user, setUser] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [optionsApoRet, setOptionsApoRet] = useState([]);
  const [selectedCuenta, setSelectedCuenta] = useState({
    value: "",
    label: "Unificada",
    tipo: "U",
    documentado: false,
  });

  const pageSize = config.cajaRecordsPerPage;
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const pdfDocument = <Report data={reportData} />;
  const [pdfInstance, updatePdfInstance] = usePDF({ document: pdfDocument });

  const filesUrl = config.filesUrl + "/cajasdomo/";
  const serviceFolder = "cajasdomo";

  useEffect(() => {
    if (!mounted) {
      if (permisos) {
        const permisosAux = { ...permisos };
        permisosAux.canModify = (selectedCuenta.tipo === "U" || selectedCuenta.tipo === "X" || selectedCuenta.documentado) && permisosOriginal.canModify;
        permisosAux.canAdd = (selectedCuenta.tipo === "U" || selectedCuenta.tipo === "X" || selectedCuenta.documentado) && permisosOriginal.canAdd;
        setPermisos(permisosAux);
      }
      const { onLoading, onDoneLoading } = props;
      onLoading();
      async function fetchData() {
        const jwt = localStorage.getItem("token");
        const decoded = jwt ? jwtDecode(jwt) : null;
        if (!decoded) return this.props.history.replace("/login");
        const user = await getUser(decoded.user._id);
        if (!user) this.props.history.replace("/noconnection");
        setUser(user);
        const permisos = await getPermisos(decoded.user._id);
        if (!permisos) return this.props.history.replace("/notallowed");
        var found = permisos.find((element) => element.menu.link === menuLink);
        setIsAdmin(user.profile.isAdmin);
        setIsSuperAdmin(user.isSuperAdmin);
        if (!found && !user.profile.isAdmin && !user.isSuperAdmin) return props.history.replace("/notallowed");
        else {
          const canDo = {
            canAdd: user.profile.isAdmin || user.isSuperAdmin ? true : found.canAdd,
            canModify: user.profile.isAdmin || user.isSuperAdmin ? true : found.canModify,
            canDelete: user.profile.isAdmin || user.isSuperAdmin ? true : found.canDelete,
            canList: user.profile.isAdmin || user.isSuperAdmin ? true : found.canList,
            canExport: user.profile.isAdmin || user.isSuperAdmin ? true : found.canExport,
            special1: user.profile.isAdmin || user.isSuperAdmin ? true : found.special1,
            special2: user.profile.isAdmin || user.isSuperAdmin ? true : found.special2,
            special3: user.profile.isAdmin || user.isSuperAdmin ? true : found.special3,
            special4: user.profile.isAdmin || user.isSuperAdmin ? true : found.special4,
            special5: user.profile.isAdmin || user.isSuperAdmin ? true : found.special5,
            special6: user.profile.isAdmin || user.isSuperAdmin ? true : found.special6,
            special7: user.profile.isAdmin || user.isSuperAdmin ? true : found.special7,
            special8: user.profile.isAdmin || user.isSuperAdmin ? true : found.special8,
            special9: user.profile.isAdmin || user.isSuperAdmin ? true : found.special9,
            special10: user.profile.isAdmin || user.isSuperAdmin ? true : found.special10,
          };
          setPermisos(canDo);
          setPermisosOriginal(canDo);
          const date = moment().startOf("month").format("YYYY-MM-DD");
          setDesde(date);
          try {
            const setup = await getCajaSetup();
            //   setCategorias(setup.categorias);
            setCategoriasOriginal(setup.categorias);
            setSelectedMoneda(setup.monedaReferencia);
            setMonedas(setup.monedas);

            setCuentas(setup.cuentas);
            // setProyectos(setup.proyectos);
            setProyectosOriginal(setup.proyectos);
            setPersonas(setup.personas);
            let destinosAux = [];
            destinosAux.push({ label: "Proyectos", options: setup.proyectos });
            destinosAux.push({ label: "Socios", options: setup.personas });
            setDestinos(destinosAux);
            await fetchCuenta(selectedCuenta.tipo, selectedCuenta.value, setup.monedaReferencia.value, date, currentPage);
          } catch (ex) {
            console.log("network error");
            console.log(ex);
          }
        }
      }
      fetchData();
      onDoneLoading();
      setMounted(true);
    }
    return () => {
      console.log("component is unmounting");
    };
  }, []);

  useEffect(() => {
    // resetRecord();
    // if (permisos) {
    //   const permisosAux = { ...permisos };
    //   permisosAux.canModify = (selectedCuenta.tipo === "U" || selectedCuenta.tipo === "X" || selectedCuenta.documentado) && permisosOriginal.canModify;
    //   permisosAux.canAdd = (selectedCuenta.tipo === "U" || selectedCuenta.tipo === "X" || selectedCuenta.documentado) && permisosOriginal.canAdd;
    //   setPermisos(permisosAux);
    // }
    // let saldo = 0;
    let rd = { ...initialReportData };
    cajas.map((caja, index) => {
      let labelCategoria = "";
      let labelDestino = "";
      let found = "";
      // if (!(caja.formaPago === "CTA" || caja.formaPago === "TRF" || caja.formaPago === "ECH")) saldo += caja.ingreso - caja.egreso;
      // saldo += caja.ingreso - caja.egreso;
      // caja.saldo = saldo;
      found = categorias.find((object) => object.value === caja.categoriaId);
      if (found) labelCategoria = found.label;
      found = "";
      let row = [dateFormatter(caja.fecha), labelDestino, caja.label, caja.detalle, numberWithCommas(caja.ingreso), numberWithCommas(caja.egreso), numberWithCommas(caja.saldo)];
      // reportData.items.push(row);
      rd.items.push(row);
    });
    setReportData(rd);
  }, [cajas]);

  // useEffect(() => {
  //   updatePdfInstance();
  // }, [reportData]);

  const onDataLoading = () => {
    setDataLoading(true);
  };

  const onDoneDataLoading = () => {
    setDataLoading(false);
  };

  const resetRecord = () => {
    const limpio = {
      _id: "",
      fecha: moment().format("YYYY-MM-DD"),
      destino: {},
      proyecto: {},
      persona: {},
      presupuesto: {},
      categoria: {},
      rubro: {},
      detalle: "",
      ingreso: 0,
      egreso: 0,
      documentado: false,
      formaPago: "EFV",
      selectedFormaPago: formasPago.find((element) => element.value === "EFV"),
      fechaFactura: moment().format("YYYY-MM-DD"),
      numeroFactura: "",
      totalFactura: 0,
      iva: 0,
      archivos: [],
      parentId: null,
    };
    setNewRecord(limpio);
    setCategorias(categoriasOriginal);
  };

  const fetchCuenta = async (tipo, idCaja, idMoneda, startingDate, pagina) => {
    let saldo = 0;
    onDataLoading();
    const cajas = await getCajas(tipo, idCaja, idMoneda, startingDate, pagina);
    cajas.map((item) => {
      if (!(item.formaPago === "CTA" || item.formaPago === "TRF" || item.formaPago === "ECH")) saldo += item.ingreso - item.egreso;
      item.saldo = saldo;
    });
    const totalCount = cajas.length > 0 ? cajas[0].totalCount : 0;
    setCurrentPage(Math.max(Math.ceil(totalCount / pageSize), 1));
    setTotalCount(totalCount);
    // setFullCajas(cajas);
    // const records = paginate(cajas, Math.max(Math.ceil(totalCount / pageSize), 1), pageSize);
    // setCajas(records);
    setCajas(cajas);
    onDoneDataLoading();
  };

  const handleApoRepFocus = () => {
    const options = [...optionsApoRet];
    options.forEach((item) => (item.label = item.pendiente));
    setOptionsApoRet(options);
  };

  const handleApoRepFocusOut = () => {
    const options = [...optionsApoRet];
    options.forEach((item) => (item.label = item.nombre + " " + item.apellido));
    setOptionsApoRet(options);
  };

  const handleChangeApoRet = async (selectedOption) => {
    newRecord.ingreso = selectedOption.maximo;
    newRecord.limitaIngreso = selectedOption.maximo;
    newRecord.persona = { label: selectedOption.nombre + " " + selectedOption.apellido, value: selectedOption.value };
    // console.log(selectedMoneda.value);
    // console.log(newRecord.categoria.value);
    // const detalle = await getNextAporte(newRecord.destino.value, selectedOption.value, selectedMoneda.value, newRecord.categoria.value);
    // console.log(detalle);
    // if (detalle.proximo === 0) {
    // toastInfo("No hay aportes pendientes en " + selectedMoneda.simbolo + " para ese proyecto");
    // } else {
    // if (detalle.proximo!==)
    // newRecord.detalle = detalle.label;

    // newRecord.ingreso = detalle.maximo;
    // newRecord.limitaIngreso = detalle.maximo;
    // }
    let newValue = selectedOption;
    newValue.label = selectedOption.nombre + " " + selectedOption.apellido;
    newRecord.persona = newValue;
    setNewRecord({ ...newRecord });
  };

  const handleAdd = () => {
    resetRecord();
    setStatus("A");
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // const records = paginate(fullCajas, page, pageSize);
    // setCajas(records);
  };

  const handleChangeCuenta = (selectedOption, name) => {
    setStatus("");
    setSelectedCuenta(selectedOption);
    fetchCuenta(selectedOption.tipo, selectedOption.value, selectedMoneda.value, desde, currentPage);
  };

  const handleChangeMoneda = (selectedOption, name) => {
    const { onLoading, onDoneLoading } = props;
    setStatus("");
    setSelectedMoneda(selectedOption);
    fetchCuenta(selectedCuenta.tipo, selectedCuenta.value, selectedOption.value, desde, currentPage);
  };

  const handleChangeFecha = (event) => {
    setNewRecord({ ...newRecord, fecha: event.target.value });
  };

  const handleChangeDesde = (event) => {
    setDesde(event.target.value);
  };

  const handleDateKeyPress = (event) => {
    if (event.charCode === 13) fetchCuenta(selectedCuenta.tipo, selectedCuenta.value, selectedMoneda.value, desde, currentPage);
  };

  const handleNewDesde = () => {
    fetchCuenta(selectedCuenta.tipo, selectedCuenta.value, selectedMoneda.value, desde, currentPage);
  };

  const handleEdit = async (found) => {
    setOriginalRecord(newRecord);
    setStatus("M");
    if (newRecord.destino.tipo === "P") {
      const proyecto = await getProyecto(newRecord.destino.value);
      let presupuestos = [];
      if (proyecto.presupuestos) {
        let pre = proyecto.presupuestos.filter((m) => m.estado === "Aceptado");
        pre.forEach((presu) => {
          const insert = {
            value: presu._id,
            label: presu.descripcion,
            contratista: presu.contratista,
            tipo: "P",
          };
          presupuestos.push(insert);
        });
        let cats = [];
        cats.push({ label: "Presupuestos", options: presupuestos });
        cats.push({ label: "Categorías", options: categoriasOriginal });
        setCategorias(cats);
      }
    } else {
      let cats = [];
      cats.push({ label: "Categorías", options: categoriasOriginal.filter((item) => item.afectaSocios) });
      setCategorias(cats);
    }

    // if (newRecord.destino.tipo === "P") {
    //   const proyecto = await getProyecto(newRecord.destino.value);
    //   let presupuestos = [];
    //   if (proyecto.presupuestos) {
    //     let pre = proyecto.presupuestos.filter((m) => m.estado === "Aceptado");
    //     pre.forEach((presu) => {
    //       const insert = {
    //         value: presu._id,
    //         label: presu.descripcion,
    //         tipo: "P",
    //       };
    //       presupuestos.push(insert);
    //     });
    //   }
    //   let cats = [];
    //   cats.push({ label: "Presupuestos", options: presupuestos });
    //   cats.push({ label: "Categorías", options: categoriasOriginal.filter((item) => !item.afectaSocios) });
    //   setCategorias(cats);
    // } else {
    //   setCategorias(categoriasOriginal.filter((item) => item.afectaSocios));
    // }
  };

  const openDocumentadoModal = (record) => {
    record.ivaFactura = formattedToFloat(record.totalFactura) * (1 - 1 / 1.21);
    setModalOpened(true);
  };

  const closeDocumentadoModal = () => {
    setModalOpened(false);
  };

  const saveDocumentadoModal = async () => {
    if (newRecord.selectedFormaPago && newRecord.selectedFormaPago.value) newRecord.formaPago = newRecord.selectedFormaPago.value;
    newRecord.totalFactura = formattedToFloat(newRecord.totalFactura);
    try {
      const response = await saveCajaRecord(newRecord);
      if (response.data.success) {
        let regCajas = [...cajas];
        // let fCajas = [...fullCajas];
        let index = regCajas.findIndex((item) => item._id === newRecord._id);
        regCajas[index] = { ...newRecord };
        let saldo = 0;
        regCajas.forEach((item) => {
          if (!(item.formaPago === "CTA" || item.formaPago === "TRF" || item.formaPago === "ECH")) saldo += item.ingreso - item.egreso;
          item.saldo = saldo;
        });

        // index = fCajas.findIndex((item) => item._id === newRecord._id);
        // fCajas[index] = { ...newRecord };
        setCajas(regCajas);
        // setFullCajas(fCajas);
        closeDocumentadoModal();
      } else toastBigError("Error", "No se pudo actualizar el registro!");
    } catch (error) {
      toastBigError("Error", "No se pudo actualizar el registro!");
    }
  };

  const handleSave = async () => {
    const { onLoading } = props;
    setStatus("");
    const saveRecord = {
      _id: newRecord._id,
      fecha: newRecord.fecha,
      destino: newRecord.destino,
      proyecto: newRecord.destino.tipo === "S" && newRecord.proyecto ? newRecord.proyecto.value : newRecord.destino.tipo === "P" ? newRecord.destino.value : "",
      persona: newRecord.destino.tipo === "S" ? newRecord.destino.value : newRecord.persona ? newRecord.persona.value : "",
      presupuesto: newRecord.categoria.tipo === "P" ? newRecord.categoria.value : "",
      categoria: newRecord.categoria.tipo === "C" ? newRecord.categoria.value : "",
      rubro: newRecord.rubro ? newRecord.rubro.label : "",
      moneda: selectedMoneda.value,
      detalle: newRecord.detalle,
      documentado: newRecord.documentado,
      ingreso: formattedToFloat(String(newRecord.ingreso)),
      egreso: formattedToFloat(String(newRecord.egreso)),
      pendiente: selectedCuenta.tipo === "X" ? true : false,
    };
    newRecord.proyecto =
      newRecord.destino.tipo === "S" && newRecord.proyecto ? { label: newRecord.proyecto.label, value: newRecord.proyecto.value } : newRecord.destino.tipo === "P" ? { label: newRecord.destino.label, value: newRecord.destino.value } : {};
    // newRecord.persona = newRecord.destino.tipo === "S" ? { label: newRecord.destino.label, value: newRecord.destino.value } : {};
    newRecord.persona = newRecord.destino.tipo === "S" ? { label: newRecord.destino.label, value: newRecord.destino.value } : newRecord.persona;
    newRecord.presupuesto = newRecord.categoria.tipo === "P" ? { label: newRecord.categoria.label, value: newRecord.categoria.value, contratista: newRecord.categoria.contratista } : {};
    newRecord.categoria = newRecord.categoria.tipo === "C" ? { label: newRecord.categoria.label, value: newRecord.categoria.value, tipo: "C" } : {};
    newRecord.ingreso = formattedToFloat(String(newRecord.ingreso));
    newRecord.egreso = formattedToFloat(String(newRecord.egreso));

    if (!isEmpty(newRecord.rubro)) newRecord.rubro = newRecord.rubro.label;
    else delete newRecord.rubro;
    if (isEmpty(newRecord.persona)) delete newRecord.persona;
    if (isEmpty(newRecord.presupuesto)) delete newRecord.presupuesto;
    if (isEmpty(newRecord.categoria)) delete newRecord.categoria;
    let origCajas = [...cajas];
    let regCajas = [...cajas];
    // let fCajas = [...fullCajas];

    if (saveRecord._id) {
      const index = regCajas.findIndex((item) => item._id === saveRecord._id);
      regCajas[index] = { ...newRecord };
      // const indexFull = fCajas.findIndex((item) => item._id === saveRecord._id);
      // fCajas[indexFull] = { ...newRecord };
    }
    try {
      let result = await saveCajaRecord(saveRecord);
      const now = moment().format("YYYY-MM-DD");
      if (!result.data.success) {
        toastBigError("No se puede generar el aporte", "Falta que socios sin crédito ingresen dinero");
        setCajas(origCajas);
      } else {
        if (result.data.success && ((status === "A" && saveRecord.fecha !== now) || (status !== "A" && saveRecord.fecha !== originalRecord.fecha))) fetchCuenta(selectedCuenta.tipo, selectedCuenta.value, selectedMoneda.value, desde, currentPage);
        else {
          if (!newRecord._id) {
            newRecord._id = result.data.inserted._id;
            if (saveRecord.presupuesto && saveRecord.egreso > 0) {
              if (!saveRecord.documentado) {
                handleShowComprobante(newRecord);
              } else {
                openDocumentadoModal(newRecord);
              }
            }
            regCajas.push(newRecord);
            // fCajas.push(newRecord);
            if (result.data.dependencies) {
              let record = { ...result.data.dependencies };

              // result.data.dependencies.forEach((record) => {
              //   // let record = result.data.dependencies;
              if (record.categoria) {
                record.categoria = categoriasOriginal.find((element) => element.value === record.categoria);
              }
              if (record.proyecto) {
                record.proyecto = proyectosOriginal.find((element) => element.value === record.proyecto && element.tipo === "P");
                if (!record.persona) {
                  record.destino = record.proyecto;
                  record.proyecto = {};
                }
              }
              if (record.persona) {
                record.destino = personas.find((element) => element.value === record.persona);
                record.persona = {};
              }
              if (record.presupuesto) {
                record.presupuesto = presupuestos.find((element) => element.value === record.presupuesto);
              }
              regCajas.push(record);
              //   // fCajas.push(record);
              // });
            }
            // setTotalCount(fCajas.length);
          }
          let saldo = 0;
          regCajas.map((item) => {
            if (!(item.formaPago === "CTA" || item.formaPago === "TRF" || item.formaPago === "ECH")) saldo += item.ingreso - item.egreso;
            item.saldo = saldo;
          });
          if (status === "A") setCurrentPage(Math.max(Math.ceil(regCajas.length / pageSize), 1));
          setTotalCount(regCajas.length);
          setCajas(regCajas);
          // setFullCajas(fCajas);
        }
      }
    } catch (error) {
      setCajas(origCajas);
    }
  };

  const handleShowDocumentado = (record) => {
    newRecord._id = record._id;
    newRecord.fecha = record.fecha;
    newRecord.destino = record.destino;
    newRecord.proyecto = record.proyecto;
    newRecord.persona = record.persona;
    newRecord.presupuesto = record.presupuesto;
    newRecord.categoria = record.categoria;
    newRecord.rubro = record.rubro;
    newRecord.detalle = record.detalle;
    newRecord.ingreso = record.ingreso;
    newRecord.egreso = record.egreso;
    newRecord.documentado = record.documentado;
    newRecord.formaPago = record.formaPago;
    newRecord.selectedFormaPago = formasPago.find((element) => element.value === record.formaPago);
    newRecord.fechaFactura = moment(record.fechaFactura).format("YYYY-MM-DD");
    newRecord.numeroFactura = record.numeroFactura;
    newRecord.totalFactura = record.totalFactura;
    newRecord.iva = newRecord.totalFactura * (1 - 1 / 1.21);
    newRecord.archivos = [];
    openDocumentadoModal(newRecord);
  };

  const handleShowComprobante = async (record) => {
    const contractor = record.presupuesto.contratista.nombre.trim() + " " + record.presupuesto.contratista.apellido.trim();
    const blob = await pdf(
      <Comprobante
        user={user}
        fecha={record.fecha}
        detalle={record.detalle}
        titular={record.destino.titular}
        monto={record.egreso}
        presupuesto={record.presupuesto.label}
        proyecto={record.destino.label}
        moneda={selectedMoneda.label}
        simbolo={selectedMoneda.simbolo}
        contratista={contractor}
      />
    ).toBlob();
    const pdfUrl = URL.createObjectURL(blob);
    window.open(pdfUrl, "_blank");
    URL.revokeObjectURL(pdfUrl);
  };

  const handleCancel = () => {
    setStatus("");
  };

  const handleDelete = async (id) => {
    toastConfirm("¿ Eliminar registro ?", () => doDelete(id));
  };

  const doDelete = async (id) => {
    let origCajas = [...cajas];
    let regCajas = [...cajas];
    const index = regCajas.findIndex((item) => item._id === id);
    regCajas.splice(index, 1);
    regCajas = regCajas.filter((item) => item.parentId !== id);
    let saldo = 0;
    regCajas.map((item) => {
      if (!(item.formaPago === "CTA" || item.formaPago === "TRF" || item.formaPago === "ECH")) saldo += item.ingreso - item.egreso;
      item.saldo = saldo;
    });
    setCajas(regCajas);
    try {
      await deleteCajaRecord(id);
      if (regCajas.length === 0) fetchCuenta(selectedCuenta.tipo, selectedCuenta.value, selectedMoneda.value, desde, currentPage);
    } catch (error) {
      setCajas(origCajas);
    }
  };

  const handleChangeDestino = async (selectedOption) => {
    newRecord.destino = selectedOption;
    switch (selectedOption.tipo) {
      case "P": {
        const proyecto = await getProyecto(selectedOption.value);
        let presupuestos = [];
        if (proyecto.presupuestos) {
          let pre = proyecto.presupuestos.filter((m) => m.estado === "Aceptado");
          pre.forEach((presu) => {
            const insert = {
              value: presu._id,
              label: presu.descripcion,
              contratista: presu.contratista,
              tipo: "P",
            };
            presupuestos.push(insert);
          });
          let cats = [];
          cats.push({ label: "Presupuestos", options: presupuestos });
          cats.push({ label: "Categorías", options: categoriasOriginal });
          setCategorias(cats);
        }
        break;
      }
      case "Q": {
        const proyecto = await getProyecto(selectedOption.value);
        let presupuestos = [];
        if (proyecto.presupuestos) {
          let pre = proyecto.presupuestos.filter((m) => m.estado === "Aceptado");
          pre.forEach((presu) => {
            const insert = {
              value: presu._id,
              label: presu.descripcion,
              contratista: presu.contratista,
              tipo: "P",
            };
            presupuestos.push(insert);
          });
          let cats = [];
          cats.push({ label: "Presupuestos", options: presupuestos });
          cats.push({ label: "Categorías", options: categoriasOriginal.filter((item) => !item.afectaSocios) });
          setCategorias(cats);
        }
        break;
      }
      default: {
        let cats = [];
        cats.push({ label: "Categorías", options: categoriasOriginal.filter((item) => item.afectaSocios) });
        setCategorias(cats);
      }
    }
    newRecord.detalle = "";
    newRecord.proyecto = {};
    newRecord.categoria = {};
    delete newRecord.limitaIngreso;
    setNewRecord({ ...newRecord });
  };

  const handleChangeProyecto = async (selectedOption) => {
    newRecord.proyecto = selectedOption;
    if (newRecord.categoria.label.toUpperCase().includes("APORTE")) {
      let detalle = await getNextAporte(selectedOption.value, newRecord.destino.value, selectedMoneda.value, newRecord.categoria.value);
      if (detalle.proximo === 0) {
        toastInfo("No hay aportes pendientes en " + selectedMoneda.simbolo + " para ese proyecto");
      } else {
        newRecord.detalle = detalle.label;
        newRecord.ingreso = detalle.maximo;
        newRecord.limitaIngreso = detalle.maximo;
      }
    }
    setNewRecord({ ...newRecord });
  };

  const handleChangeCategoria = async (selectedOption) => {
    newRecord.detalle = "";
    newRecord.proyecto = {};
    newRecord.categoria = selectedOption;
    delete newRecord.limitaIngreso;
    if (newRecord.destino.tipo === "P") {
      if (selectedOption.afectaSocios) {
        if (selectedOption.idSistema === "#APORTES") {
          let optApoRep = [];
          const aportes = await getAportesPendientes(newRecord.destino.value, selectedMoneda.value, selectedOption.value);
          newRecord.destino.socios.forEach((socio) => {
            let socApoRep = [];
            const tienePendientes = aportes.aportes.filter((aporte) => aporte.persona._id === socio.persona._id);
            tienePendientes.forEach((pendiente) => {
              optApoRep.push({
                _id: pendiente.persona._id,
                value: pendiente.persona._id,
                label: socio.persona.nombre + " " + socio.persona.apellido + " Pendiente: " + numberWithCommas(pendiente.monto),
                pendiente: socio.persona.nombre + " " + socio.persona.apellido + " Pendiente: " + numberWithCommas(pendiente.monto),
                maximo: pendiente.monto,
                nombre: socio.persona.nombre,
                apellido: socio.persona.apellido,
              });
              // socApoRep.push({
              //   value: pendiente.persona._id,
              //   label: "Aporte" + " - " + numberWithCommas(pendiente.monto),
              //   maximo: pendiente.monto,
              //   nombre: socio.persona.nombre + " " + socio.persona.apellido
              // });
            });
            // if (socApoRep.length > 0) optApoRep.push({ label: socio.persona.nombre + " " + socio.persona.apellido, options: socApoRep });
          });
          setOptionsApoRet(optApoRep);

          //   const detalle = await getNextAporteProyecto(newRecord.destino.value, selectedMoneda.value, selectedOption.value);
          //   if (detalle.proximo === 0) {
          //     toastInfo("No hay aportes pendientes en " + selectedMoneda.simbolo + " para ese proyecto");
          //   } else {
          //     newRecord.detalle = detalle.label;
          //     newRecord.ingreso = detalle.maximo;
          //     newRecord.limitaIngreso = detalle.maximo;
          //   }
        }
      } else {
        if (selectedOption.tipo === "C") {
          const rubros = await getRubros(selectedOption.value);
          setRubros(rubros);
        }
        if (selectedOption.tipo === "P") {
          const proximo = await getNextCuota(selectedOption.value, selectedMoneda.value);
          newRecord.detalle = proximo.label;
        }
      }
    } else {
      if (selectedOption.tipo === "C" && selectedOption.afectaSocios) {
        setProyectos(proyectosOriginal.filter((item) => item.socios.find((socio) => socio.persona._id === newRecord.destino.value) && item.tipo === "P"));
      }
    }
    setNewRecord({ ...newRecord });
  };

  const handleChangeRubro = (selectedOption) => {
    newRecord.rubro = selectedOption;
    setNewRecord({ ...newRecord });
  };

  const handleRecordChange = (event) => {
    const record = { ...newRecord };
    record[event.currentTarget.name] = event.currentTarget.value;
    if (event.currentTarget.name === "ingreso") record.egreso = 0;
    if (event.currentTarget.name === "egreso") record.ingreso = 0;
    record.ivaFactura = formattedToFloat(record.totalFactura) * (1 - 1 / 1.21);
    setNewRecord(record);
  };

  const handleComboChange = (selectedValue, propertyName) => {
    const record = { ...newRecord };
    record[propertyName] = selectedValue;
    // if (event.currentTarget.name === "ingreso") record.egreso = 0;
    // if (event.currentTarget.name === "egreso") record.ingreso = 0;
    setNewRecord(record);
  };

  const handleToggleDocumentado = (event) => {
    setNewRecord({ ...newRecord, documentado: !newRecord.documentado });
  };

  const records = paginate(cajas, currentPage, pageSize);

  return (
    <div>
      <ContentHeader title={title} breadcrumbs={breadcrumbs} />
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          {dataLoading && (
            <div className="preloadercaja justify-content-center align-items-center">
              <i className="mt-5 text-secondary fas fa-2x fa-sync fa-spin"></i>
            </div>
          )}
          {!dataLoading && (
            <>
              <div className="row ">
                {/* left column */}
                <div className="col-12">
                  <div
                    className="card card-secondary"
                    style={{
                      backgroundColor: "#" + selectedMoneda.backcolor,
                    }}
                  >
                    <div className="card-header">
                      <div className="row align-bottom">
                        <div className="col-7">
                          <Input combo="true" options={cuentas} value={selectedCuenta} isDisabled={status} onChange={handleChangeCuenta} />
                          {/* <Combo options={cuentas} value={selectedCuenta} className="form-select" isDisabled={status} onChange={this.handleChangeCuenta} /> */}
                        </div>
                        <div className="col-2">
                          <Input date name="desde" value={desde} onBlur={handleNewDesde} onChange={handleChangeDesde} onKeyPress={handleDateKeyPress} />
                          {/* <Combo options={monedas} value={selectedMoneda} className="form-select" isDisabled={status} onChange={this.handleChangeMoneda} /> */}
                        </div>
                        <div className="col-2">
                          <Input combo="true" options={monedas} value={selectedMoneda} isDisabled={status} onChange={handleChangeMoneda} />
                          {/* <Combo options={monedas} value={selectedMoneda} className="form-select" isDisabled={status} onChange={this.handleChangeMoneda} /> */}
                        </div>
                        <div className="col-1">
                          <button
                            type="button"
                            className="btn mt-1 "
                            style={{
                              backgroundColor: "#b10f10",
                              color: "white",
                            }}
                          >
                            <PDFDownloadLink document={pdfDocument} fileName="cuenta.pdf" style={{ color: "white" }}>
                              {({ blob, url, loading, error }) => updatePdfInstance && (loading ? "Generando..." : "PDF")}
                            </PDFDownloadLink>
                            <i className="fa fa-file-pdf pr-2 ml-2" aria-hidden="true"></i>
                          </button>
                          {/* <ReactToPrint
                            content={() => this.componentRef}
                            trigger={() => (
                              <button
                                type="button"
                                className="btn mt-1 "
                                style={{
                                  backgroundColor: "#b10f10",
                                  color: "white",
                                }}
                              >
                                <i className="fa fa-file-pdf pr-2" aria-hidden="true"></i>
                                Exportar
                              </button>
                            )}
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div
                      className="card-body"
                      ref={(response) => {
                        componentRef = response;
                      }}
                    >
                      <Cuenta
                        simbolo={selectedMoneda.simbolo}
                        unificada={selectedCuenta.value ? false : true}
                        tipoCuenta={selectedCuenta.tipo}
                        canEdit={permisos.canModify}
                        // cajas={cajas}
                        cajas={records}
                        categorias={categorias}
                        optionsApoRet={optionsApoRet}
                        rubros={rubros}
                        destinos={destinos}
                        proyectos={proyectos}
                        presupuestos={presupuestos}
                        personas={personas}
                        status={status}
                        isAdmin={isAdmin}
                        isSuperAdmin={isSuperAdmin}
                        onReset={resetRecord}
                        onEdit={handleEdit}
                        onSave={handleSave}
                        onCancel={handleCancel}
                        newRecord={newRecord}
                        onDelete={handleDelete}
                        onChangeFecha={handleChangeFecha}
                        onChangeDestino={handleChangeDestino}
                        onChangeProyecto={handleChangeProyecto}
                        onChangeCategoria={handleChangeCategoria}
                        onChangeRubro={handleChangeRubro}
                        onShowComprobante={(cajaRecord) => handleShowComprobante(cajaRecord)}
                        onShowDocumentado={(cajaRecord) => handleShowDocumentado(cajaRecord)}
                        onChangeSelectedApoRet={handleChangeApoRet}
                        onRecordChange={handleRecordChange}
                        onApoRepFocus={handleApoRepFocus}
                        onApoRepFocusOut={handleApoRepFocusOut}
                        onToggleDocumentado={handleToggleDocumentado}
                      />
                      <div className="row ml-1">
                        {permisos.canAdd && selectedCuenta.tipo === "U" && (
                          <button type="button" className="btn btn-secondary" onClick={() => handleAdd()}>
                            <i className="fa fa-plus pr-2" aria-hidden="true"></i>
                            Nuevo
                          </button>
                        )}
                      </div>
                      <div className="row mt-1 align-items-center">
                        <div className="col-sm-12 col-md-5">
                          <Records itemsCount={totalCount} pageSize={pageSize} currentPage={currentPage} />
                        </div>

                        <div className="col-sm-12 col-md-7" style={{ textAlign: "right" }}>
                          <Pagination onPageChange={(page) => handlePageChange(page)} itemsCount={totalCount} pageSize={pageSize} currentPage={currentPage} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.row */}
              </div>
              <Modal show={modalOpened}>
                <Modal.Header>
                  <Modal.Title>
                    <strong>Pago Documentado</strong>
                  </Modal.Title>
                  <button type="button" className="close" onClick={closeDocumentadoModal}>
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">Cerrar</span>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Input name="fechaFactura" label="Fecha Factura" float columns="4" date autoFocus onChange={handleRecordChange} value={newRecord.fechaFactura} />
                    <Input name="numeroFactura" label="N° Factura" float columns="4" autoFocus onChange={handleRecordChange} value={newRecord.numeroFactura} />
                  </Row>
                  <Row>
                    <Input name="totalFactura" label="Total Factura" float number columns="4" autoFocus onChange={handleRecordChange} value={newRecord.totalFactura} />
                    <Input name="ivaFactura" label="I.V.A" float number disabled columns="4" autoFocus value={newRecord.ivaFactura} />
                  </Row>
                  <Row>
                    <Input name="selectedFormaPago" label="Forma de Pago" float columns="5" combo options={formasPago} autoFocus onChange={(event) => handleComboChange(event, "selectedFormaPago")} value={newRecord.selectedFormaPago} />
                  </Row>
                  <Row>
                    <div className="col-12">
                      <FileBrowser
                        status="A"
                        recordId={newRecord._id}
                        serviceFolder={serviceFolder}
                        filesUrl={filesUrl}
                        canCreateFolder={false}
                        // archivos={archivos}
                        // onFileDelete={(index, subfolder) => this.handleFileDelete(index, subfolder)}
                        // onFileUpload={(files, subfolder) => this.handleFileUpload(files, subfolder)}
                        // onCreateFolder={this.handleCreateFolder}
                        // onChangeFolder={(url) => this.handleFolderChange(url)}
                        // onRename={(url, original, newName) => this.handleRename(url, original, newName)}
                      />
                    </div>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={closeDocumentadoModal}>
                    Cerrar
                  </Button>
                  <Button variant="secondary" onClick={saveDocumentadoModal}>
                    Guardar
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  );
}

import { PDFViewer, BlobProvider } from "@react-pdf/renderer";
import Comprobante from "./comprobante";

const PDFShow = () => {
  return (
    <BlobProvider document={<Comprobante />}>
      {({ blob, url, loading, error }) => {
        // Do whatever you need with blob here
        return <div>There's something going on on the fly</div>;
      }}
    </BlobProvider>
  );
};

export default PDFShow;

import http from "./httpService";
import { getConfig } from "../config/config.js";
const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/presupuestos";

export async function getPresupuestos() {
  try {
    const response = await http.get(serviceEndpoint);
    return response.data.records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function getPresupuesto(recordId) {
  try {
    const response = await http.get(serviceEndpoint + "/" + recordId);
    return response.data.record;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return null;
  }
}

// export async function deleteFile(id, file) {
//   return await http.post(serviceEndpoint + "/deleteFile/" + id, {
//     archivo: file,
//   });
// }

export async function savePresupuesto(data) {
  if (data._id) return await http.put(serviceEndpoint + "/" + data._id, data);
  else {
    try {
      const response = await http.post(serviceEndpoint, data);
      return response.data.record._id;
    } catch (error) {
      if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
      else return null;
    }
  }
}

// export async function deleteProyecto(recordId) {
//   return await http.delete(serviceEndpoint + "/" + recordId);
// }

// export async function getProyectosSelect() {
//   let proyectos = [];
//   try {
//     const response = await http.get(serviceEndpoint);
//     response.data.records.map((proyecto) => {
//       proyectos.push({ value: proyecto._id, label: proyecto.nombre });
//     });
//     return proyectos;
//   } catch (error) {
//     if (error.message.toUpperCase().includes("NETWORK ERROR"))
//       return error.message.toUpperCase();
//     else return [];
//   }
// }

export async function uploadFilesPresupuesto(id, data) {
  const newData = new FormData();
  data.forEach((file, index) => {
    newData.append("file", file);
  });
  try {
    const response = await http.post(serviceEndpoint + "/uploadFiles/" + id, newData);
    return response.data.archivos;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return null;
  }
}

export async function deleteFile(id, file) {
  return await http.post(serviceEndpoint + "/deleteFile/" + id, {
    archivo: file,
  });
}

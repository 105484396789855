import React from "react";
import Form from "../common/newForm";
import { withRouter } from "react-router-dom";
import Joi from "joi-browser";
import moment from "moment";
import { getMovimientosPresupuestos } from "../../services/cajasDomoService.js";
import { getPresupuesto, savePresupuesto } from "../../services/presupuestosService";
import { personasPromiseOptions, getPersona, getPersonasSelect } from "../../services/personasService";
import { proyectosPromiseOptions, getProyecto, getProyectosSelect } from "../../services/proyectosService";
import { estadosPresupuestos, formatCurrencyToFloat, formattedToFloat } from "../utils/utils";
import { getMonedasSimboloSelect } from "../../services/monedasService.js";
import { getConfig } from "../../config/config.js";
import ReactTabHotkeyReplacer from "react-tab-hotkey-replacer/dist/ReactTabHotkeyReplacer";
import RoundButton from "../common/roundButton";
import FileBrowser from "../fileBrowser/fileBrowser";
import ChildArray from "../common/childArray";
// import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { getCategoriasSelect } from "../../services/categoriasService.js";

class PresupuestosForm extends Form {
  title = "Presupuesto";

  breadcrumbs = [
    {
      title: "Inicio",
      link: "/main",
    },
    {
      title: "Presupuestos",
      link: "/presupuestos",
    },
    {
      title: "Detalles",
    },
  ];

  validateForm = true;

  adicionalesFormat = [
    { label: "Fecha", width: "20%", column: "fecha", type: "date" },
    { label: "Detalle", width: "50%", column: "detalle", type: "text" },
    { label: "Monto", width: "25%", column: "monto", type: "number", showWithCommas: "true" },
  ];

  state = {
    action: "C",
    adicional: {
      fecha: moment().format("DD/MM/YYYY"),
      detalle: "",
      monto: 0,
    },

    data: {
      proyecto: "",
      categoria: "",
      contratista: "",
      descripcion: "",
      estado: "",
      moneda: "",
      adicionales: [{ fecha: moment(), detalle: "Presupuesto Original", monto: 0 }],
      archivos: [],
      iva: 0,
      // monedadocumentado: "",
      // total: 0,
      // totaldocumentado: 0,
    },
    percIva: 0,
    percTotal: 0,
    // percdocumentado: 0,
    categorias: [],
    monedas: [],
    estado: { label: "Pedido", value: "Pedido" },
    errors: {},
  };

  schema = {
    _id: Joi.string(),
    descripcion: Joi.string()
      .label("Descripcion")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "any.empty":
              return { message: "Debe ingresar una descripcion" };
          }
        });
      }),
    iva: Joi.number()
      .label("IVA Proyectado")
      .error((errors) => {
        return errors.map((error) => {
          // console.log(error.type);
          switch (error.type) {
            case "number.base":
              return { message: "Debe ingresar un valor" };
          }
        });
      }),
    estado: Joi.string()
      .label("Estado")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "object.base":
              return { message: "Debe ingresar un estado" };
          }
        });
      }),
    proyecto: Joi.string()
      .label("Proyecto")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "object.base":
              return { message: "Debe ingresar un proyecto" };
          }
        });
      }),
    moneda: Joi.string()
      .label("Moneda")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "object.base":
              return { message: "Debe ingresar moneda" };
          }
        });
      }),
    categoria: Joi.string()
      .label("Categoría")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "object.base":
              return { message: "Debe ingresar una categoría" };
          }
        });
      }),
    contratista: Joi.string()
      .label("Contratista")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "object.base":
              return { message: "Debe ingresar un contratista" };
          }
        });
      }),
    adicionales: Joi.array(),
    archivos: Joi.array(),
  };

  componentDidMount = async () => {
    this.hookMounted();
    const { onLoading, onDoneLoading } = this.props;
    const recordId = this.props.match.params.id;
    let record = {};
    onLoading();
    const categorias = await getCategoriasSelect();
    const monedas = await getMonedasSimboloSelect();
    this.setState({ monedas, categorias });
    if (recordId !== "nuevo" && recordId !== "nueva") {
      record = await getPresupuesto(recordId);
      const movimientos = await getMovimientosPresupuestos(recordId, false);
      this.setState({ perc: movimientos.totalSinDocumentar + movimientos.totalDocumentado, percIva: movimientos.ivaFacturado });
    }

    onDoneLoading();
    if (recordId === "nuevo" || recordId === "nueva") {
      const presupuestado = estadosPresupuestos.find((item) => item.label === "Presupuestado");
      const data = { ...this.state.data };
      data.estado = presupuestado;
      this.setState({ data });
      return;
    } else {
      if (!record) return this.props.history.replace("/notfound");
      this.setState({
        data: this.mapToViewModel(record),
      });
    }
  };

  mapToViewModel = (presupuesto) => {
    return {
      _id: presupuesto._id,
      descripcion: presupuesto.descripcion,
      archivos: presupuesto.archivos,
      iva: presupuesto.iva ? presupuesto.iva : 0,
      // total: presupuesto.total,
      // totaldocumentado: presupuesto.totaldocumentado,
      // proyecto: presupuesto.proyecto
      //   ? {
      //       value: presupuesto.proyecto._id,
      //       label: presupuesto.proyecto.nombre,
      //     }
      //   : { label: "", value: "" },
      moneda: presupuesto.moneda
        ? {
            value: presupuesto.moneda._id,
            label: presupuesto.moneda.simbolo,
          }
        : { label: "", value: "" },
      categoria: presupuesto.categoria
        ? {
            value: presupuesto.categoria._id,
            label: presupuesto.categoria.descripcion,
          }
        : { label: "", value: "" },
      // monedadocumentado: presupuesto.monedadocumentado
      //   ? {
      //       value: presupuesto.monedadocumentado._id,
      //       label: presupuesto.monedadocumentado.simbolo,
      //     }
      //   : { label: "", value: "" },
      adicionales: presupuesto.adicionales ? presupuesto.adicionales : [],
      proyecto: presupuesto.proyecto
        ? {
            value: presupuesto.proyecto._id,
            label: presupuesto.proyecto.nombre,
          }
        : { label: "", value: "" },
      contratista: presupuesto.contratista
        ? {
            value: presupuesto.contratista._id,
            label: presupuesto.contratista.apellido + " " + presupuesto.contratista.nombre,
          }
        : { label: "", value: "" },
      estado: estadosPresupuestos.find((item) => item.label === presupuesto.estado),
    };
  };

  handleChangeAdicionales = (array) => {
    // console.log("change adicionales");
    // console.log(array);
    const { data } = this.state;
    data.adicionales = array;
    this.setState({ data });
  };

  prepareData = () => {
    const { data } = this.state;
    const iva = formatCurrencyToFloat(data.iva);
    data.estado = data.estado.value;
    data.proyecto = data.proyecto.value;
    data.moneda = data.moneda.value;
    data.categoria = data.categoria.value;
    data.contratista = data.contratista.value;
    data.iva = iva;
    if (data.adicionales) {
      data.adicionales.map((adicional) => {
        adicional.monto = formattedToFloat(adicional.monto);
      });
    }
    this.setState({ data });
  };

  doSubmit = async () => {
    try {
      await savePresupuesto(this.state.data);
      this.props.history.push("/presupuestos");
    } catch (error) {
      // console.log(error);
    }
  };

  render() {
    const { action } = this.state;
    const { data, errors, perc, percIva, monedas, categorias, adicional } = this.state;
    const { adicionales, iva } = this.state.data;
    const config = getConfig();
    const filesUrl = config.filesUrl + "/presupuestos/";
    const serviceFolder = "presupuestos";
    let totalGeneral = 0;
    adicionales.map((adicional) => {
      totalGeneral += formattedToFloat(adicional.monto);
    });
    const progress = (isNaN(perc / totalGeneral) ? 0 : perc / totalGeneral) * 100;
    const progressIva = (isNaN(percIva / ((totalGeneral * iva) / 100)) ? 0 : percIva / ((totalGeneral * iva) / 100)) * 100;
    return (
      <React.Fragment>
        {/* Content Header (Page header) */}
        {/* <ContentHeader title={this.title} breadcrumbs={this.breadcrumbs} /> */}
        {this.renderContentHeader(this.title, this.breadcrumbs, this.goBack)}

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row ">
              {/* left column */}
              <div className="col-12">
                <div className="card card-secondary mt-2">
                  <div className="card-header">
                    <h3 className="card-title">
                      {" "}
                      <RoundButton onClick={() => this.goBack()} iconClass="fas fa-arrow-left" className="btn-transparent mr-2" tooltip="Volver" />
                      Detalles
                    </h3>
                  </div>
                  <form onSubmit={this.handleSubmit}>
                    <ReactTabHotkeyReplacer>
                      <div className="card-body">
                        <div className="row">
                          {this.renderInput("descripcion", "Descripción", "text", {
                            float: true,
                            columns: 12,
                            autoFocus: true,
                          })}
                        </div>
                        <div className="row">
                          {this.renderInput("proyecto", "Proyecto", "asyncCombo", {
                            float: true,
                            loadOptions: proyectosPromiseOptions,
                            columns: 6,
                          })}
                          {this.renderInput("contratista", "Contratista", "asyncCombo", {
                            float: true,
                            loadOptions: personasPromiseOptions,
                            columns: 6,
                          })}
                        </div>

                        <div className="row">
                          {this.renderInput("estado", "Estado", "combo", {
                            float: true,
                            options: estadosPresupuestos,
                            columns: 4,
                          })}
                          {this.renderInput("categoria", "Categoría", "combo", {
                            float: true,
                            options: categorias,
                            columns: 3,
                          })}
                          {this.renderInput("moneda", "Moneda", "combo", {
                            float: true,
                            options: monedas,
                            columns: 1,
                          })}
                          {this.renderInput("iva", " % IVA Proyectado", "number", {
                            float: true,
                            columns: 2,
                            suffix: "%",
                          })}
                        </div>
                        <div className="row mb-1">
                          {<ChildArray data={adicionales} blankItem={adicional} editable={action === "C" ? false : true} format={this.adicionalesFormat} label="Adicional(es)" onChange={this.handleChangeAdicionales} onIsEditing={() => {}} />}
                        </div>

                        <h4>Progreso Pagos</h4>
                        <h5>Progreso IVA</h5>
                        <div className="row align-items-center mt-2 mb-5">
                          <div className="col-11">
                            <div className="progress progress-sm">
                              <div className={"progress-bar " + (progressIva < 33 ? "bg-danger" : progressIva < 66 ? "bg-warning" : "bg-success")} style={{ width: progressIva + "%" }}></div>
                            </div>
                          </div>
                          <div className="col-1">
                            <div className={"badge " + (progressIva < 33 ? "badge-danger" : progressIva < 66 ? "badge-warning" : "badge-success")}>
                              <h3>&nbsp;{progressIva.toFixed(2).toLocaleString()}%&nbsp;</h3>
                            </div>
                          </div>
                        </div>
                        <h5>General</h5>
                        <div className="row align-items-center mt-2 mb-5">
                          <div className="col-11">
                            <div className="progress progress-sm">
                              <div className={"progress-bar " + (progress < 33 ? "bg-danger" : progress < 66 ? "bg-warning" : "bg-success")} style={{ width: progress + "%" }}></div>
                            </div>
                          </div>
                          <div className="col-1">
                            <div className={"badge " + (progress < 33 ? "badge-danger" : progress < 66 ? "badge-warning" : "badge-success")}>
                              <h3>&nbsp;{progress.toFixed(2).toLocaleString()}%&nbsp;</h3>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <FileBrowser
                              status={action}
                              recordId={data._id}
                              serviceFolder={serviceFolder}
                              filesUrl={filesUrl}
                              canCreateFolder={true}
                              // archivos={archivos}
                              // onFileDelete={(index, subfolder) => this.handleFileDelete(index, subfolder)}
                              // onFileUpload={(files, subfolder) => this.handleFileUpload(files, subfolder)}
                              // onCreateFolder={this.handleCreateFolder}
                              // onChangeFolder={(url) => this.handleFolderChange(url)}
                              // onRename={(url, original, newName) => this.handleRename(url, original, newName)}
                            />
                          </div>

                          {/* <div className="col-6 text-center">
                            <>
                              <CircularProgressbar
                                className="progress-presupuesto"
                                value={isNaN(perc / this.state.data.total) ? 0 : perc / this.state.data.total}
                                maxValue={1}
                                text={`${Math.round((isNaN(perc / this.state.data.total) ? 0 : perc / this.state.data.total) * 10000) / 100}%`}
                              />
                              <h4 className="mt-2">Progreso Presupuesto</h4>
                            </>
                          </div> */}
                        </div>
                      </div>

                      <div className="card-footer">{this.renderSubmit(this.state.data._id ? "Guardar" : "Continuar")}</div>
                    </ReactTabHotkeyReplacer>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(PresupuestosForm);

import React, { useState } from "react";
import { Document, Page, View, Text, Image, StyleSheet } from "@react-pdf/renderer";
import { imageExists, numberWithCommas, valorALetras } from "../utils/utils";
import { getConfig } from "../../config/config.js";
import moment from "moment";

const Comprobante = (props) => {
  const { fecha, user, titular, monto, detalle, proyecto, presupuesto, moneda, simbolo, contratista } = props;
  let logo = "";

  const config = getConfig();

  const styles = StyleSheet.create({
    page: { fontSize: 11, paddingTop: 20, paddingLeft: 40, paddingRight: 40, lineHeight: 1.5, flexDirection: "column" },
    titleContainer: { flexDirection: "row", alignContent: "flex-end", alignItems: "end", justifyContent: "space-between", marginTop: 24, marginLeft: 12, marginRight: 12 },
    titleFecha: { fontFamily: "Helvetica", fontSize: 10 },
    body: { flexDirection: "row", marginTop: 24, marginLeft: 12, marginRight: 12 },
    bodyText: { fontFamily: "Helvetica", fontSize: 11 },
    boldBodyText: { fontFamily: "Helvetica-Bold", fontSize: 11 },
    firma: { flexDirection: "row", justifyContent: "flex-end", marginTop: 50, marginLeft: 400, marginRight: 12 },
    firmaCol: { flex: 1, flexDirection: "col", justifyContent: "center", marginTop: 50, marginLeft: 30, marginRight: 30 },
    firmaPuntos: { textAlign: "center", fontFamily: "Helvetica", fontSize: 8 },
    firmaNombre: { textAlign: "center", fontFamily: "Helvetica", fontSize: 10 },
    logo: { width: 90 },
  });

  if (imageExists(config.filesUrl + "/empresas/" + user.empresa.logoPapel)) logo = config.filesUrl + "/empresas/" + user.empresa.logoPapel;

  return (
    <Document>
      <Page size="A4" styles={styles.page}>
        <View style={styles.titleContainer}>
          {/* <Image style={styles.logo} src={logo} /> */}
          <Text style={styles.titleFecha}>{moment(fecha).format("dddd Do [de] MMMM [de] YYYY")}</Text>
        </View>
        <View style={styles.body}>
          <Text style={styles.bodyText}>
            Recibí de <Text style={styles.boldBodyText}>{titular}</Text> la suma de{" "}
            <Text style={styles.boldBodyText}>
              {moneda + " " + valorALetras(monto)} {"(" + simbolo + " " + numberWithCommas(monto) + ")"}
            </Text>{" "}
            por <Text style={styles.boldBodyText}>{detalle} </Text> de presupuesto <Text style={styles.boldBodyText}>{presupuesto}</Text> del proyecto <Text style={styles.boldBodyText}>{proyecto}</Text>
          </Text>
        </View>
        <View style={styles.firma}>
          <View style={styles.firmaCol}>
            <Text style={styles.firmaPuntos}>..................................</Text>
            <Text style={styles.firmaNombre}>{contratista}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Comprobante;

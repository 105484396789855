import React, { useState, useEffect } from "react";
import { dateFormatter, formattedToFloat } from "../utils/utils";
import NumberFormat from "react-number-format";
import Input from "./input";
import moment from "moment";
import ReactTabHotkeyReplacer from "react-tab-hotkey-replacer/dist/ReactTabHotkeyReplacer";
import { isEmpty } from "lodash";

export default function Cuenta(props) {
  // let saldo = 0;

  const [editItem, setEditItem] = useState(-1);

  const {
    simbolo,
    optionsApoRet,
    tipoCuenta,
    cajas,
    categorias,
    rubros,
    proyectos,
    destinos,
    onSave,
    onCancel,
    newRecord,
    onReset,
    onEdit,
    onChangeFecha,
    onChangeDestino,
    onChangeCategoria,
    onChangeRubro,
    onChangeProyecto,
    onRecordChange,
    onToggleDocumentado,
    onShowComprobante,
    onShowDocumentado,
    onChangeSelectedApoRet,
    onDelete,
    onApoRepFocus,
    onApoRepFocusOut,
    isAdmin,
    isSuperAdmin,
    status,
    canEdit,
  } = props;

  const editSave = () => {
    setEditItem(-1);
    onSave();
  };

  const canAdd = (record) => {
    let lCanAdd = true;
    if (!record.destino || isEmpty(record.destino)) lCanAdd = false;
    else {
      if (record.destino.tipo === "P") {
        if (!record.categoria || isEmpty(record.categoria)) lCanAdd = false;
      } else {
      }
    }
    return lCanAdd;
  };

  const showComprobante = (cajaRecord) => {
    onShowComprobante(cajaRecord);
  };

  const showDocumentado = (cajaRecord) => {
    onShowDocumentado(cajaRecord);
  };

  const resetNewRecord = () => {
    const limpio = {
      _id: "",
      fecha: moment().format("YYYY-MM-DD"),
      destino: {},
      proyecto: {},
      persona: {},
      presupuesto: {},
      categoria: {},
      rubro: {},
      detalle: "",
      ingreso: 0,
      egreso: 0,
      cotizacion: 0,
      documentado: false,
      pendiente: false,
    };
  };

  const editRecord = async (index) => {
    resetNewRecord();
    newRecord._id = cajas[index]._id;
    newRecord.fecha = moment(cajas[index].fecha).format("YYYY-MM-DD");
    newRecord.detalle = cajas[index].detalle;
    newRecord.archivos = cajas[index].archivos;
    newRecord.documentado = cajas[index].documentado;
    newRecord.ingreso = cajas[index].ingreso;
    newRecord.egreso = cajas[index].egreso;
    newRecord.destino = cajas[index].destino;
    newRecord.categoria = {};
    newRecord.rubro = cajas[index].rubro;
    newRecord.cotizacion = cajas[index].cotizacion;
    newRecord.label = cajas[index].label;
    newRecord.moneda = cajas[index].moneda;
    newRecord.pendiente = cajas[index].pendiente;
    newRecord.proyecto = cajas[index].proyecto;
    // newRecord.presupuesto = cajas[index].presupuesto;
    newRecord.fechaFactura = cajas[index].fechaFactura;
    newRecord.numeroFactura = cajas[index].numeroFactura;
    newRecord.totalFactura = cajas[index].totalFactura;
    newRecord.ivaFactura = formattedToFloat(newRecord.totalFactura) * (1 - 1 / 1.21);
    newRecord.formaPago = cajas[index].formaPago;
    if (!isEmpty(cajas[index].presupuesto)) newRecord.categoria = { ...cajas[index].presupuesto, tipo: "P" };
    if (!isEmpty(cajas[index].categoria)) {
      newRecord.categoria = { label: cajas[index].categoria.label, value: cajas[index].categoria.value, tipo: "C" };
      newRecord.rubro = cajas[index].rubro ? { label: cajas[index].rubro, value: cajas[index].rubro } : {};
    }
    newRecord.proyecto = {};
    newRecord.persona = {};
    // if (!isEmpty(cajas[index].presupuesto)) newRecord.categoria = { label: cajas[index].presupuesto.label, value: cajas[index].presupuesto.value, tipo: "P" };
    // if (!isEmpty(cajas[index].categoria)) {
    //   newRecord.categoria = { label: cajas[index].catecoria.label, value: cajas[index].categoria.value, tipo: "C" };
    //   newRecord.rubro = cajas[index].rubro ? { label: cajas[index].rubro, value: cajas[index].rubro } : {};
    // }
    // newRecord.proyecto = {};
    // newRecord.persona = {};
    setEditItem(index);
    await onEdit();
  };

  const editCancel = () => {
    onReset();
    setEditItem(-1);
  };

  const deleteRecord = (index) => {
    onDelete(index);
  };

  const isIngresoAllowed = (values, limita) => {
    if (!limita) return true;
    else {
      const { floatValue } = values;
      return floatValue <= limita;
    }
  };

  return (
    <div>
      <ReactTabHotkeyReplacer>
        <form>
          <table className="table table-sm table-hover table-striped">
            <thead>
              <tr>
                <th width="10%">Fecha</th>
                {tipoCuenta === "S" && <th width="15%">Proyecto</th>}
                {tipoCuenta === "P" && <th width="15%">Socio</th>}
                {tipoCuenta !== "P" && tipoCuenta !== "S" && <th width="15%">Proyecto/Socio</th>}
                <th width="15%">Categoría/Presupuesto</th>
                <th width="10%">Rubro/Detalle</th>
                <th width="10%"></th>
                <th className="text-center" width="10%">
                  Ingreso
                </th>
                <th className="text-center" width="10%">
                  Egreso
                </th>
                <th className="text-center" width="10%">
                  Saldo
                </th>
                <th className="text-center" width="10%"></th>
              </tr>
            </thead>
            <tbody>
              {cajas.map((caja, index) => {
                let labelCategoria = "";
                let labelDestino = "";
                // if (!(caja.formaPago === "CTA" || caja.formaPago === "TRF" || caja.formaPago === "ECH")) saldo += caja.ingreso - caja.egreso;
                return (
                  <tr key={`caja${index}`} className={caja.formaPago === "CTA" || caja.formaPago === "TRF" || caja.formaPago === "ECH" ? "no-suma" : ""}>
                    {index !== editItem && (
                      <React.Fragment>
                        <td>{dateFormatter(caja.fecha)}</td>
                        {caja.destino && <td>{caja.destino.label}</td>}
                        {caja.destino && caja.destino.tipo === "P" && caja.presupuesto && (
                          <>
                            <td>{caja.presupuesto.label}</td>
                            <td colSpan="2">{caja.detalle}</td>
                          </>
                        )}
                        {caja.destino && caja.destino.tipo === "P" && caja.categoria && caja.categoria.tipo !== "P" && (
                          <>
                            <td>{caja.categoria.label}</td>
                            {caja.rubro && (
                              <>
                                <td>{caja.rubro}</td>
                                <td>{caja.detalle}</td>
                              </>
                            )}
                            {!caja.rubro && caja.persona && <td colSpan="2">{caja.persona.nombre + " " + caja.persona.apellido}</td>}
                            {!caja.rubro && !caja.persona && <td colSpan="2">{caja.detalle}</td>}
                          </>
                        )}
                        {caja.destino && caja.destino.tipo === "S" && caja.parentId && (
                          <>
                            <td>{caja.categoria && caja.categoria.label}</td>
                            <td colSpan="2">{caja.proyecto && <>{caja.proyecto.label}</>}</td>
                            {/* <td colSpan="3">{caja.detalle}</td> */}
                            {/* <td>{caja.detalle}</td> */}
                          </>
                        )}
                        {caja.destino && caja.destino.tipo === "S" && !caja.parentId && (
                          <>
                            <td>{caja.categoria && caja.categoria.label}</td>
                            {/* <td colSpan="2">{caja.proyecto && <>{cajacaja.proyecto.label}</>}</td> */}
                            <td colSpan="2">{caja.detalle}</td>
                            {/* <td>{caja.detalle}</td> */}
                          </>
                        )}
                        {!caja.destino && (
                          <>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{caja.detalle}</td>
                          </>
                        )}
                        <td className="text-right">
                          {caja.ingreso > 0 && (
                            <>
                              {simbolo} <NumberFormat value={caja.ingreso} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} fixedDecimalScale={true} />
                            </>
                          )}
                        </td>

                        <td className="text-right">
                          {caja.egreso > 0 && (
                            <>
                              {simbolo} <NumberFormat value={caja.egreso} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} fixedDecimalScale={true} />
                            </>
                          )}
                        </td>
                        <td className={"text-right " + (caja.saldo < 0 ? "text-danger" : "")}>
                          {
                            <>
                              {simbolo} <NumberFormat value={caja.saldo} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} fixedDecimalScale={true} />
                            </>
                          }
                        </td>
                        <td>
                          {caja.destino && caja.destino.tipo === "P" && caja.egreso > 0 && !caja.documentado && caja.presupuesto && <i className="fa-receipt mr-2 fas clickable" onClick={() => showComprobante(caja)}></i>}
                          <i className={"fa-balance-scale mr-2 fa text-secondary " + `${caja.documentado ? "visible" : "invisible"}`} onClick={() => showDocumentado(caja)}></i>
                          {(isAdmin || isSuperAdmin) && caja.destino && canEdit && !caja.parentId && <i className="fas fa-pencil-alt mr-2 clickable text-olive-disabled" onClick={() => editRecord(index)}></i>}
                          {(isAdmin || isSuperAdmin) && caja.destino && canEdit && !caja.parentId && <i className="fas fa-trash-alt mr-2 clickable text-danger-disabled" onClick={() => deleteRecord(caja._id)}></i>}
                        </td>
                      </React.Fragment>
                    )}
                    {index === editItem && (
                      <React.Fragment>
                        {(isAdmin || isSuperAdmin) && (
                          <td className="align-middle" width="10%">
                            <Input date size="sm" name="addFecha" value={newRecord.fecha} onChange={onChangeFecha} />
                          </td>
                        )}
                        {!(isAdmin || isSuperAdmin) && <td>{dateFormatter(newRecord.fecha)}</td>}
                        <td className="align-middle" width="15%">
                          <Input combo size="sm" value={newRecord.destino} options={destinos} onChange={onChangeDestino} />
                        </td>
                        {newRecord.destino && !newRecord.destino.value && (
                          <>
                            <td className="align-middle" width="15%">
                              <div className="cajaloader"></div>
                            </td>
                            <td className="align-middle" width="10%">
                              <div className="cajaloader"></div>
                            </td>
                            <td className="align-middle" width="10%">
                              <div className="cajaloader"></div>
                            </td>
                            <td className="align-middle" width="10%">
                              <div className="cajaloader"></div>
                            </td>
                            <td className="align-middle" width="10%">
                              <div className="cajaloader"></div>
                            </td>
                            <td className="align-middle" width="10%">
                              <div className="cajaloader"></div>
                            </td>
                          </>
                        )}
                        {newRecord.destino && newRecord.destino.value && newRecord.destino.tipo !== "S" && (
                          <td className="align-middle">
                            <Input combo size="sm" value={newRecord.categoria} options={categorias} onChange={onChangeCategoria} />
                          </td>
                        )}
                        {newRecord.destino && newRecord.destino.tipo && newRecord.destino.tipo === "S" && (
                          <>
                            <td className="align-middle">
                              <Input combo size="sm" value={newRecord.categoria} options={categorias} onChange={onChangeCategoria} />
                            </td>
                            <td className="align-middle">
                              <Input combo size="sm" value={newRecord.proyecto} options={proyectos} onChange={onChangeProyecto} />
                            </td>
                            <td className="align-middle">
                              <Input name="detalle" size="sm" disabled value={newRecord.detalle} onChange={onRecordChange} />
                            </td>
                          </>
                        )}
                        {newRecord.destino && newRecord.destino.tipo === "P" && newRecord.categoria.tipo !== "P" && !newRecord.categoria.afectaSocios && (
                          <>
                            <td className="align-middle">
                              <Input combo size="sm" value={newRecord.rubro} options={rubros} onChange={onChangeRubro} />
                            </td>
                            <td className="align-middle">
                              <Input name="detalle" size="sm" value={newRecord.detalle} onChange={onRecordChange} />
                            </td>
                          </>
                        )}
                        {newRecord.destino && newRecord.destino.tipo === "P" && newRecord.categoria.tipo !== "P" && newRecord.categoria.afectaSocios && (
                          <>
                            <td className="align-middle" colSpan="2">
                              <Input disabled name="detalle" size="sm" value={newRecord.detalle} onChange={onRecordChange} />
                            </td>
                          </>
                        )}
                        {newRecord.destino && newRecord.destino.tipo === "P" && newRecord.categoria.tipo === "P" && (
                          <>
                            <td colSpan="2" className="align-middle">
                              <Input name="detalle" size="sm" value={newRecord.detalle} onChange={onRecordChange} />
                            </td>
                          </>
                        )}
                        {newRecord.destino && newRecord.destino.value && (
                          <>
                            <td className="align-middle">
                              <Input
                                disabled={newRecord.categoria.idSistema && newRecord.categoria.idSistema === "#RETIROS"}
                                number
                                size="sm"
                                name="ingreso"
                                value={newRecord.ingreso}
                                onChange={onRecordChange}
                                isAllowed={(values) => isIngresoAllowed(values, newRecord.limitaIngreso)}
                              />
                            </td>
                            <td className="align-middle">
                              <Input disabled={newRecord.categoria.idSistema && newRecord.categoria.idSistema === "#APORTES"} number size="sm" name="egreso" value={newRecord.egreso} onChange={onRecordChange} />
                            </td>
                            <td className="align-middle"></td>
                          </>
                        )}{" "}
                        <td width="10%">
                          <i className={"fa-balance-scale clickable mr-2 " + (newRecord.documentado ? "fas text-primary" : "fa text-secondary") + (newRecord.destino.tipo === "P" ? " visible" : " invisible")} onClick={onToggleDocumentado}></i>
                          <button type="button" disabled={!canAdd(newRecord)} className="btn btn-transparent ml-3">
                            <i className={"fa fa-check clickable text-olive-disabled "} onClick={editSave}></i>
                          </button>
                          <button type="button" className="btn btn-transparent">
                            <i className="fa fa-times clickable text-danger-disabled" onClick={editCancel}></i>
                          </button>
                        </td>
                      </React.Fragment>
                    )}
                  </tr>
                );
              })}
              {status === "A" && editItem < 0 && (
                <tr>
                  {(isAdmin || isSuperAdmin) && (
                    <td className="align-middle" width="10%">
                      <Input date size="sm" name="addFecha" value={newRecord.fecha} onChange={onChangeFecha} />
                    </td>
                  )}
                  {!(isAdmin || isSuperAdmin) && <td width="10%">{dateFormatter(newRecord.fecha)}</td>}

                  <td className="align-middle" width="15%">
                    <Input combo size="sm" value={newRecord.destino} options={destinos} onChange={onChangeDestino} />
                  </td>
                  {newRecord.destino && !newRecord.destino.value && (
                    <>
                      {/* <td className="align-middle" width="15%">
                        <div className="cajaloader"></div>
                      </td> */}
                      <td className="align-middle" width="10%">
                        <div className="cajaloader"></div>
                      </td>
                      <td className="align-middle" width="10%">
                        <div className="cajaloader"></div>
                      </td>
                      <td className="align-middle" width="10%">
                        <div className="cajaloader"></div>
                      </td>
                      <td className="align-middle" width="10%">
                        <div className="cajaloader"></div>
                      </td>
                      <td className="align-middle" width="10%">
                        <div className="cajaloader"></div>
                      </td>
                    </>
                  )}

                  {newRecord.destino && newRecord.destino.value && newRecord.destino.tipo !== "S" && (
                    <td className="align-middle">
                      <Input combo size="sm" value={newRecord.categoria} options={categorias} onChange={onChangeCategoria} />
                    </td>
                  )}

                  {newRecord.destino && newRecord.destino.tipo && newRecord.destino.tipo === "S" && (
                    <>
                      <td className="align-middle">
                        <Input combo size="sm" value={newRecord.categoria} options={categorias} onChange={onChangeCategoria} />
                      </td>
                      {/* <td className="align-middle">
                        <Input combo size="sm" value={newRecord.proyecto} options={proyectos} onChange={onChangeProyecto} />
                      </td> */}
                      <td colSpan="2" className="align-middle">
                        <Input name="detalle" size="sm" disabled value={newRecord.detalle} onChange={onRecordChange} />
                      </td>
                    </>
                  )}

                  {newRecord.destino && newRecord.destino.tipo === "P" && newRecord.categoria.tipo !== "P" && !newRecord.categoria.afectaSocios && (
                    <>
                      <td className="align-middle">
                        <Input combo size="sm" value={newRecord.rubro} options={rubros} onChange={onChangeRubro} />
                      </td>
                      <td className="align-middle">
                        <Input name="detalle" size="sm" value={newRecord.detalle} onChange={onRecordChange} />
                      </td>
                    </>
                  )}
                  {newRecord.destino && newRecord.destino.tipo === "P" && newRecord.categoria.tipo !== "P" && newRecord.categoria.afectaSocios && (
                    <>
                      {/* <td className="align-middle" colSpan="2">
                        <Input disabled name="detalle" size="sm" value={newRecord.detalle} onChange={onRecordChange} />
                      </td> */}
                      <td className="align-middle" colSpan="2">
                        <Input combo size="sm" value={newRecord.persona} options={optionsApoRet} onFocus={onApoRepFocus} onBlur={onApoRepFocusOut} onChange={onChangeSelectedApoRet} />
                      </td>
                    </>
                  )}
                  {newRecord.destino && newRecord.destino.tipo === "P" && newRecord.categoria.tipo === "P" && (
                    <>
                      <td colSpan="2" className="align-middle">
                        <Input name="detalle" disabled size="sm" value={newRecord.detalle} onChange={onRecordChange} />
                      </td>
                    </>
                  )}
                  {newRecord.destino && newRecord.destino.value && (
                    <>
                      <td className="align-middle">
                        <Input
                          disabled={(newRecord.categoria.idSistema && newRecord.categoria.idSistema === "#RETIROS") || newRecord.categoria.tipo === "P"}
                          number
                          size="sm"
                          name="ingreso"
                          value={newRecord.ingreso}
                          onChange={onRecordChange}
                          isAllowed={(values) => isIngresoAllowed(values, newRecord.limitaIngreso)}
                        />
                      </td>
                      <td className="align-middle">
                        <Input disabled={newRecord.categoria.idSistema && newRecord.categoria.idSistema === "#APORTES"} number size="sm" name="egreso" value={newRecord.egreso} onChange={onRecordChange} />
                      </td>
                      <td className="align-middle"></td>
                    </>
                  )}

                  <td width="10%">
                    <i className={"fa-balance-scale clickable mr-2 " + (newRecord.documentado ? "fas text-primary" : "fa text-secondary") + (newRecord.destino.tipo === "P" ? " visible" : " invisible")} onClick={onToggleDocumentado}></i>
                    <button type="button" disabled={!canAdd(newRecord)} className="btn btn-transparent ml-3">
                      <i className={"fa fa-check " + (canAdd(newRecord) ? "clickable text-olive-disabled " : "text-gray")} onClick={onSave}></i>
                    </button>
                    <button type="button" className="btn btn-transparent">
                      <i className="fa fa-times clickable text-danger-disabled" onClick={onCancel}></i>
                    </button>
                  </td>
                  {/* <td></td> */}
                </tr>
              )}
            </tbody>
          </table>
        </form>
      </ReactTabHotkeyReplacer>
    </div>
  );
}

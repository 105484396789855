import http from "./httpService";
import { getConfig } from "../config/config.js";
const config = getConfig();

export async function getFiles(id, serviceFolder, subfolder) {
  try {
    const serviceEndpoint = config.apiEndpoint + "/" + serviceFolder + "/";
    const response = await http.post(serviceEndpoint + "/getFiles/" + id, { subfolder: subfolder });
    return response.data.archivos;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return null;
  }
}

export async function renameFile(id, serviceFolder, subfolder, original, newName) {
  try {
    const serviceEndpoint = config.apiEndpoint + "/" + serviceFolder + "/";
    const response = await http.post(serviceEndpoint + "/renameFile/" + id, { original: original, newName: newName, subfolder: subfolder });
    return response.data.archivos;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return null;
  }
}

export async function uploadImages(id, serviceFolder, subfolder, data) {
  const newData = new FormData();
  const serviceEndpoint = config.apiEndpoint + "/" + serviceFolder + "/";
  if (subfolder === "") subfolder = "__null";
  data.forEach((file, index) => {
    newData.append("file", file);
  });
  try {
    const response = await http.post(serviceEndpoint + "uploadImages/" + id + "/" + subfolder.replace("/", "º"), newData);
    return response.data.archivos;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return null;
  }
}

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export async function updateImages(id, serviceFolder, subfolder, imageFile, imageData) {
  const newData = new FormData();
  const serviceEndpoint = config.apiEndpoint + "/" + serviceFolder + "/";
  if (subfolder === "") subfolder = "__null";
  newData.append("file", dataURLtoFile(imageData, imageFile));

  // data.forEach((file, index) => {
  //   newData.append("file", file);
  // });
  try {
    const response = await http.put(serviceEndpoint + "/updateImages/" + id + "/" + subfolder.replace("/", "º") + "/" + imageFile, newData);
    return response.data.archivos;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return null;
  }
}

export async function uploadFiles(id, serviceFolder, subfolder, data) {
  const newData = new FormData();
  const serviceEndpoint = config.apiEndpoint + "/" + serviceFolder + "/";
  if (subfolder === "") subfolder = "__null";
  console.log(serviceEndpoint);
  data.forEach((file, index) => {
    console.log(file);
    newData.append("file", file);
  });
  try {
    const response = await http.post(serviceEndpoint + "/uploadFiles/" + id + "/" + subfolder.replace("/", "º"), newData);
    return response.data.archivos;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return null;
  }
}

export async function deleteFile(id, serviceFolder, subfolder, file) {
  const serviceEndpoint = config.apiEndpoint + "/" + serviceFolder + "/";
  if (subfolder === "") subfolder = "__null";
  return await http.post(serviceEndpoint + "/deleteFile/" + id + "/" + subfolder.replace("/", "º"), {
    archivo: file,
  });
}

export async function createFolder(id, serviceFolder, subfolder) {
  try {
    const serviceEndpoint = config.apiEndpoint + "/" + serviceFolder + "/";
    if (subfolder === "") subfolder = "__null";
    const response = await http.post(serviceEndpoint + "/createFolder/" + id);
    return response.data.archivos;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return null;
  }
}

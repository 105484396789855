import React from "react";
import Form from "../common/newForm";
import Input from "../common/input";
import NumberFormat from "react-number-format";
import moment from "moment";

import { withRouter } from "react-router-dom";
import Joi from "joi-browser";
import { getProyecto, saveProyecto, getProyectoStatus } from "../../services/proyectosService";
import { personasPromiseOptions, getPersona, getPersonasSelect } from "../../services/personasService";
import { getMonedasSimboloSelect } from "../../services/monedasService";
import { getCotizacion } from "../../services/cotizacionesService.js";
import { getNextAporte } from "../../services/cajasDomoService";
import { getConfig } from "../../config/config.js";
import ReactTabHotkeyReplacer from "react-tab-hotkey-replacer/dist/ReactTabHotkeyReplacer";
import RoundButton from "../common/roundButton";
import FileBrowser from "../fileBrowser/fileBrowser";
import { formattedToFloat, numberWithCommas } from "../utils/utils.js";

class ProyectosForm extends Form {
  title = "Proyecto";

  breadcrumbs = [
    {
      title: "Inicio",
      link: "/main",
    },
    {
      title: "Proyectos",
      link: "/proyectos",
    },
    {
      title: "Detalles",
    },
  ];

  validateForm = true;

  state = {
    action: "C",
    socio: {
      persona: { value: "", label: "" },
      participacion: 0,
    },
    subproyecto: {
      nombre: "",
    },
    child: {
      contratista: "",
      descripcion: "",
      estado: "Pedido",
      totalsindocumento: "",
      totaldocumentado: "",
    },
    data: {
      nombre: "",
      ubicacion: "",
      socios: [],
      titular: "",
      matricula: "",
      nomenclatura: "",
      notas: "",
      partida: "",
      archivos: [],
      presupuestos: [],
      aportes: [],
      subproyectos: [],
    },
    titular: "",
    // titularId: "",
    personaId: "",
    persona: "",
    estado: { label: "Pedido", value: "Pedido" },
    contratista: "",
    personas: [],
    monedas: [],
    participacion: "",
    errors: {},
    childAction: "",
    childIndex: 0,
    editingAporte: false,
    status: null,
  };

  preSchema = {
    _id: Joi.string().allow(""),
    nombre: Joi.string()
      .label("Nombre")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            // case "string.min":
            //   return { message: "first msg" };
            // case "string.max":
            //   return { message: "second msg" };
            case "any.empty":
              return { message: "Debe ingresar un nombre" };
          }
        });
      }),
    ubicacion: Joi.string().allow(""),
    matricula: Joi.string().allow(""),
    partida: Joi.string().allow(""),
    nomenclatura: Joi.string().allow(""),
    notas: Joi.string().allow(""),
    socios: Joi.array(),
    aportes: Joi.array(),
    presupuestos: Joi.array(),
    presupuestos: Joi.array(),
    archivos: Joi.array(),
    suproyectos: Joi.array(),
    titular: Joi.string()
      .required()
      .label("Titular")
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            // case "string.min":
            //   return { message: "first msg" };
            // case "string.max":
            //   return { message: "second msg" };
            case "any.empty":
              return { message: "Debe ingresar un titular" };
          }
        });
      }),
  };

  postSchema = {
    _id: Joi.string().allow(""),
    nombre: Joi.string()
      .label("Nombre")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            // case "string.min":
            //   return { message: "first msg" };
            // case "string.max":
            //   return { message: "second msg" };
            case "any.empty":
              return { message: "Debe ingresar un nombre" };
          }
        });
      }),
    ubicacion: Joi.string()
      .label("Ubicacion")
      .allow("")
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "any.empty":
              return { message: "Debe ingresar una ubicación" };
          }
        });
      }),
    matricula: Joi.string().label("Matrícula").allow(""),
    partida: Joi.string().label("Partida").allow(""),
    nomenclatura: Joi.string().label("Nomenclatura Catastral").allow(""),
    notas: Joi.string().label("Notas").allow(""),
    socios: Joi.array().items(),
    subproyectos: Joi.array().items(),
    presupuestos: Joi.array().items(),
    archivos: Joi.array().items(),
    aportes: Joi.array().items(),
    titular: Joi.string().required().label("Titular"),
  };

  render() {
    const { action, socio, status } = this.state;
    const { data, child, contratista, personas, estado, monedas, errors, editingAporte, subproyecto } = this.state;
    const { socios, archivos, presupuestos, aportes, subproyectos } = data;
    const config = getConfig();
    const filesUrl = config.filesUrl + "/proyectos/";
    const serviceFolder = "proyectos";
    let cantidadAportes = 0;
    let cantidadRetiros = 0;
    if (data._id) {
      this.schema = this.postSchema;
    } else {
      this.schema = this.preSchema;
    }
    if (this.state.titular) data.titular = this.state.titular;
    return (
      <React.Fragment>
        {/* Content Header (Page header) */}
        {/* <ContentHeader title={this.title} breadcrumbs={this.breadcrumbs} /> */}
        {this.renderContentHeader(this.title, this.breadcrumbs, this.goBack)}

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row ">
              {/* left column */}
              <div className="col-12">
                <div className="card card-secondary">
                  <div className="card-header">
                    <h3 className="card-title">
                      <RoundButton onClick={() => this.goBack()} iconClass="fas fa-arrow-left" className="btn-transparent mr-2" tooltip="Volver" />
                      Detalles
                    </h3>
                  </div>

                  <div className="card-header p-0 border-bottom-0">
                    <ul className="nav nav-tabs nav-form" id="custom-tabs-four-tab" role="tablist">
                      <li className="nav-item">
                        <a className="nav-link active" id="general-tab" data-toggle="pill" href="#general" role="tab" aria-controls="general" aria-selected="true">
                          Detalles Del Proyecto
                        </a>
                      </li>
                      {data._id && (
                        <>
                          <li className="nav-item">
                            <a className="nav-link" id="custom-tabs-aportes-tab" data-toggle="pill" href="#custom-aportes-messages" role="tab" aria-controls="custom-tabs-four-messages" aria-selected="false">
                              Aportes / Retiros
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" id="custom-tabs-estado-tab" data-toggle="pill" href="#custom-estado-messages" role="tab" aria-controls="custom-tabs-four-messages" aria-selected="false">
                              Estado
                            </a>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>

                  <form onSubmit={this.handleSubmit}>
                    <ReactTabHotkeyReplacer>
                      <div className="card-body">
                        <div className="tab-content" id="custom-tabs-four-tabContent">
                          <div className="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
                            <div className="card card-primary card-outline card-outline-tabs">
                              <div className="card-body">
                                <div className="tab-content" id="general-tabContent">
                                  <div className="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
                                    <div className="row ">
                                      {this.renderInput("nombre", "Nombre", "text", {
                                        float: true,
                                        columns: 6,
                                        autoFocus: true,
                                      })}
                                      {this.renderInput("titular", "Titular", "asyncCombo", {
                                        float: true,
                                        loadOptions: personasPromiseOptions,
                                        columns: 6,
                                      })}
                                    </div>
                                    {data._id && (
                                      <React.Fragment>
                                        <div className="row ">
                                          {this.renderInput("ubicacion", "Ubicación", "text", {
                                            float: true,
                                            columns: 12,
                                          })}
                                        </div>
                                        <div className="row">
                                          {this.renderInput("partida", "Partida", "text", {
                                            float: true,
                                            columns: 6,
                                          })}
                                          {this.renderInput("matricula", "Matrícula", "text", {
                                            float: true,
                                            columns: 6,
                                          })}
                                        </div>
                                        <div className="row">
                                          {this.renderInput("nomenclatura", "Nomenclatura Catastral", "text", {
                                            float: true,
                                            columns: 12,
                                          })}
                                        </div>
                                        <div className="row">{this.renderChildArray(subproyectos, subproyecto, action === "C" ? false : true, this.subproyectosFormat, "Subproyectos", this.handleChangeSubproyecto)}</div>
                                        <div className="row">
                                          {this.renderChildArray(socios, socio, action === "C" ? false : true, this.sociosFormat, "Socios", this.handleChangeSocios)}
                                          {/* <ChildArray data={socios} blankItem={socio} editable={action === "C" ? false : true} format={this.sociosFormat} label="Socios" onChange={this.handleChangeSocios} /> */}
                                        </div>
                                        <div className="row mt-2">
                                          <div className="col-6">
                                            {this.renderInput("notas", "Notas", "textarea", {
                                              float: true,
                                              rows: 10,
                                            })}
                                          </div>
                                          <div className="col-6">
                                            <FileBrowser
                                              status={action}
                                              recordId={data._id}
                                              serviceFolder={serviceFolder}
                                              filesUrl={filesUrl}
                                              canCreateFolder={true}
                                              // archivos={archivos}
                                              // onFileDelete={(index, subfolder) => this.handleFileDelete(index, subfolder)}
                                              // onFileUpload={(files, subfolder) => this.handleFileUpload(files, subfolder)}
                                              // onCreateFolder={this.handleCreateFolder}
                                              // onChangeFolder={(url) => this.handleFolderChange(url)}
                                              // onRename={(url, original, newName) => this.handleRename(url, original, newName)}
                                            />
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {/* /.card */}
                            </div>
                          </div>
                          <div className="tab-pane fade show" id="custom-aportes-messages" role="tabpanel" aria-labelledby="custom-aportes-messages-tab">
                            {action !== "C" && (
                              <div className="row">
                                <button className="btn btn-secondary buttons-copy buttons-html5 mr-1 " disabled={editingAporte} tabIndex={0} aria-controls="datatable" type="button" onClick={() => this.addAporteRetiro("A")}>
                                  <span>Nuevo Aporte</span>
                                </button>
                                <button className={"btn btn-secondary buttons-copy buttons-html5 " + (editingAporte ? "disabled" : "")} tabIndex={1} aria-controls="datatable" type="button" onClick={() => this.addAporteRetiro("R")}>
                                  <span>Nuevo Retiro</span>
                                </button>
                              </div>
                            )}
                            <div className="content-wrapper kanban mt-1" style={{ marginLeft: "0px", background: "transparent" }}>
                              <section className="content pb-3">
                                <div className="container-fluid">
                                  {aportes &&
                                    aportes.map((aporte, index) => {
                                      if (aporte.tipo === "A") cantidadAportes++;
                                      if (aporte.tipo === "R") cantidadRetiros++;
                                      return (
                                        <div key={"aporte" + index} className={"card card-row " + (aporte.tipo === "A" ? "card-success" : "card-danger")} style={{ width: "210px" }}>
                                          <div className="card-header">
                                            {aporte.tipo === "A" && <h3 className="card-title">Aporte # {cantidadAportes}</h3>}
                                            {aporte.tipo === "R" && <h3 className="card-title">Retiro # {cantidadRetiros}</h3>}
                                          </div>
                                          <div className="card-body mt-2">
                                            <Input
                                              date
                                              disabled={!aporte.isEditing}
                                              label="Fecha"
                                              name={"apFecha" + index}
                                              dato="fecha"
                                              value={aportes[index].fecha}
                                              float={true}
                                              columns={10}
                                              onChange={(event) => this.handleChangeAporte(event, index)}
                                            />
                                            <Input
                                              combo
                                              isDisabled={!aporte.isEditing}
                                              label="Moneda"
                                              name={"apMoneda" + index}
                                              value={aportes[index].moneda}
                                              float={true}
                                              columns={8}
                                              options={monedas}
                                              onChange={(event, name) => this.handleChangeAporteMoneda(event, name, index)}
                                            />
                                            <Input
                                              number
                                              disabled={!aporte.isEditing}
                                              label="Monto"
                                              name={"apMonto" + index}
                                              dato="monto"
                                              value={aportes[index].monto}
                                              float={true}
                                              columns={10}
                                              onChange={(event) => this.handleChangeAporte(event, index)}
                                            />
                                            {aportes[index].fecha && aportes[index].moneda && formattedToFloat(aportes[index].monto) > 0 && (
                                              <div>
                                                <hr />
                                                {aporte.aportesSocios.map((socio) => (
                                                  <div key={Math.random()}>
                                                    <div>
                                                      <strong>{socio.persona.apellido + ", " + socio.persona.nombre}</strong>
                                                    </div>
                                                    <div>
                                                      <NumberFormat
                                                        value={(formattedToFloat(aportes[index].monto) * socio.participacion) / 100}
                                                        displayType={"text"}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                      />
                                                    </div>
                                                  </div>
                                                ))}
                                              </div>
                                            )}
                                            {editingAporte && aporte.isEditing && (
                                              <>
                                                <hr />
                                                <div className="row kanban-bottom">
                                                  <div className="ml-1 mr-1 col-12 ">
                                                    <button className="btn btn-danger mr-1 buttons-copy buttons-html5" aria-controls="datatable" type="button" onClick={() => this.cancelAporte(index)}>
                                                      Cancelar
                                                    </button>
                                                    <button
                                                      className="btn btn-success buttons-copy buttons-html5"
                                                      disabled={!(aportes[index].fecha && aportes[index].moneda && formattedToFloat(aportes[index].monto) > 0)}
                                                      aria-controls="datatable"
                                                      type="button"
                                                      onClick={() => this.saveAporte(index)}
                                                    >
                                                      Ok
                                                    </button>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </section>
                            </div>
                          </div>
                          <div className="tab-pane fade show" id="custom-estado-messages" role="tabpanel" aria-labelledby="custom-estado-messages-tab">
                            {status && (
                              <div className="row">
                                <div className="col-12">
                                  <div className="card">
                                    {/* <div className="card-header">
                                      <h3 className="card-title">
                                        <strong>Ingresos</strong>
                                      </h3>
                                    </div> */}
                                    <div className="card-body p-0">
                                      <table className="table table-hover">
                                        <tbody>
                                          <tr>
                                            <td colSpan={3}>
                                              <h4>Ingresos</h4>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{ width: "50%" }} className="border-0">
                                              <strong>Aportes</strong>
                                            </td>
                                            <td align="right" style={{ width: "25%" }}>
                                              <strong>Realizados</strong>
                                            </td>
                                            <td align="right" style={{ width: "25%" }}>
                                              <strong>Pendientes</strong>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td align="right">u$s {numberWithCommas(status.aportesRealizados)}</td>
                                            <td align="right">u$s {numberWithCommas(status.totalAportes - status.aportesRealizados)}</td>
                                          </tr>
                                          <tr>
                                            <td colSpan={3}>
                                              <h4>Egresos</h4>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{ width: "50%" }} className="border-0">
                                              <strong>Presupuestos</strong>
                                            </td>
                                            <td align="right" style={{ width: "25%" }}>
                                              <strong>Abonado</strong>
                                            </td>
                                            <td align="right" style={{ width: "25%" }}>
                                              <strong>Pendiente</strong>
                                            </td>
                                          </tr>
                                          {status.presupuestos.map((presupuesto) => (
                                            <>
                                              <tr key={Math.random()}>
                                                <td>{presupuesto.descripcion}</td>
                                                {/* <td align="right">u$s {numberWithCommas(presupuesto.pagosindocumentar + presupuesto.pagodocumentado)}</td>
                                                <td align="right">u$s {numberWithCommas(presupuesto.totalsindocumentar + presupuesto.totaldocumentado - (presupuesto.pagosindocumentar + presupuesto.pagodocumentado))}</td> */}
                                                <td align="right">u$s {numberWithCommas(presupuesto.pagosindocumentar + presupuesto.pagodocumentado)}</td>
                                                <td align="right">u$s {numberWithCommas(presupuesto.total - presupuesto.pagosindocumentar - presupuesto.pagodocumentado)}</td>
                                              </tr>
                                            </>
                                          ))}
                                          <tr>
                                            <td style={{ width: "50%" }} className="border-0">
                                              <strong>Categorias</strong>
                                            </td>
                                            <td align="right" style={{ width: "25%" }}>
                                              <strong>Egreso</strong>
                                            </td>
                                            <td align="right" style={{ width: "25%" }}>
                                              <strong>Ingreso</strong>
                                            </td>
                                          </tr>
                                          {status.categorias.map((categoria) => (
                                            <>
                                              {categoria.rubros.map((rubro) => (
                                                <>
                                                  <tr key={Math.random()}>
                                                    <td>
                                                      {categoria.categoria} - {rubro.rubro}
                                                    </td>
                                                    <td align="right">u$s {numberWithCommas(rubro.egresos)}</td>
                                                    <td align="right">u$s {numberWithCommas(rubro.ingresos)}</td>
                                                  </tr>
                                                </>
                                              ))}
                                            </>
                                          ))}
                                          {/* <tr data-widget="expandable-table" aria-expanded="true">
                                            <td>
                                              <i className="expandable-table-caret fas fa-caret-right fa-fw"></i>
                                              219
                                            </td>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                          <tr className="expandable-body">
                                            <td>
                                              <div className="p-0">
                                                <table className="table table-hover">
                                                  <tbody>
                                                    <tr data-widget="expandable-table" aria-expanded="false">
                                                      <td>
                                                        <i className="expandable-table-caret fas fa-caret-right fa-fw"></i>
                                                        219-1
                                                      </td>
                                                      <td></td>
                                                      <td></td>
                                                    </tr>
                                                    <tr className="expandable-body">
                                                      <td>
                                                        <div className="p-0">
                                                          <table className="table table-hover">
                                                            <tbody>
                                                              <tr>
                                                                <td>219-1-1</td>
                                                                <td></td>
                                                                <td></td>
                                                              </tr>
                                                              <tr>
                                                                <td>219-1-2</td>
                                                                <td></td>
                                                                <td></td>
                                                              </tr>
                                                              <tr>
                                                                <td>219-1-3</td>
                                                                <td></td>
                                                                <td></td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    <tr data-widget="expandable-table" aria-expanded="false">
                                                      <td>
                                                        <button type="button" className="btn btn-primary p-0">
                                                          <i className="expandable-table-caret fas fa-caret-right fa-fw"></i>
                                                        </button>
                                                        219-2
                                                      </td>
                                                    </tr>
                                                    <tr className="expandable-body">
                                                      <td>
                                                        <div className="p-0">
                                                          <table className="table table-hover">
                                                            <tbody>
                                                              <tr>
                                                                <td>219-2-1</td>
                                                              </tr>
                                                              <tr>
                                                                <td>219-2-2</td>
                                                              </tr>
                                                              <tr>
                                                                <td>219-2-3</td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>219-3</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </td>
                                            <td></td>
                                            <td></td>
                                          </tr> */}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">{this.renderSubmit(data._id ? "Guardar" : "Continuar")}</div>
                    </ReactTabHotkeyReplacer>
                  </form>
                </div>
              </div>
              {/* /.row */}
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
      </React.Fragment>
    );
  }

  sociosFormat = [
    { label: "Socio", width: "20%", column: "persona", displayValue: "item.persona.apellido+(item.persona.nombre?', '+item.persona.nombre:'')", type: "asyncCombo", options: [], promiseOptions: personasPromiseOptions, getChild: getPersona },
    { label: "Participación", width: "10%", column: "participacion", type: "integer", suffix: "%" },
  ];

  subproyectosFormat = [{ label: "Nombre", width: "50%", column: "nombre", type: "text" }];

  handleChangeSocios = (array) => {
    const { data } = this.state;
    data.socios = array;
    this.setState({ data });
  };

  handleChangeSubproyecto = (array) => {
    const { data } = this.state;
    data.subproyectos = array;
    this.setState({ data });
  };

  saveAporte = async (index) => {
    const cotizacion = await getCotizacion();

    const editingAporte = false;
    const { data } = this.state;
    data.aportes[index].cotizacion = cotizacion;
    data.aportes[index].isEditing = false;
    data.aportes[index].isNew = false;
    this.setState({ editingAporte });
  };

  cancelAporte = (index) => {
    const { data } = this.state;
    const editingAporte = false;
    if (data.aportes[index].isNew) data.aportes.splice(index, 1);
    this.setState({ data, editingAporte });
  };

  handleChangeAporte = (e, i) => {
    const { currentTarget: input } = e;
    const field = input.attributes["dato"].nodeValue;
    const { data } = this.state;
    const inputMode = e.target.inputMode;
    data.aportes[i][field] = input.value;
    this.setState({ data });
  };

  handleChangeAporteMoneda = (selectedOption, name, index) => {
    const data = { ...this.state.data };
    data.aportes[index].moneda = selectedOption;
    this.setState({ data });
  };

  addAporteRetiro = (tipo) => {
    if (!this.state.editingAporte) {
      const { data } = this.state;
      const editingAporte = true;
      const newAporte = { fecha: "", moneda: "", tipo: tipo, monto: "0", isEditing: true, isNew: true, nuevo: true, cotizacion: 0 };
      const aportesSocios = [];
      data.socios.forEach((socio) => {
        const newApSoc = { persona: socio.persona, participacion: socio.participacion };
        aportesSocios.push(newApSoc);
      });
      newAporte.aportesSocios = aportesSocios;
      if (data.aportes) this.state.data.aportes.push(newAporte);
      else data.aportes = [newAporte];
      this.setState({ data, editingAporte });
    }
  };

  getStatus = async (id) => {
    const status = await getProyectoStatus(id);
    this.setState({ status });
  };

  componentDidMount = async () => {
    this.hookMounted();
    const { onLoading, onDoneLoading } = this.props;
    onLoading();
    const recordId = this.props.match.params.id;
    const personas = await getPersonasSelect();
    const monedas = await getMonedasSimboloSelect();
    this.setState({ personas, monedas });
    if (recordId === "nuevo" || recordId === "nueva") {
      onDoneLoading();
      return;
    }
    const record = await getProyecto(recordId);
    // record.socios.forEach(async (socio) => {
    //   const resp = await getNextAporte(record._id, socio.persona._id, "611abfc230206770554ab040", "6557527f65edd5d147708d7e");
    //   console.log(resp);
    // });
    this.getStatus(recordId);
    onDoneLoading();
    if (!record) return this.props.history.replace("/notfound");
    let selectedTitular = {};
    if (record.titular) {
      selectedTitular = {
        label: record.titular.apellido + " " + record.titular.nombre,
        value: record.titular._id,
      };
    } else {
      selectedTitular = { label: "", value: "" };
    }

    this.setState({
      data: this.mapToViewModel(record),
    });
  };

  validateRules = () => {
    const { socios, _id } = this.state.data;
    if (_id) {
      let total = 0;
      let errors = [];
      socios.forEach((socio) => {
        total += parseFloat(socio.participacion);
      });
      if (total !== 100) errors.push({ path: "participaciones", message: "El total de las participaciones debe sumar 100%" });
      if (errors.length > 0) return errors;
      else return null;
    } else return null;
  };

  // handleFileDelete = async (index) => {
  //   const originalFiles = [...this.state.data.archivos];
  //   let archivos = [...this.state.data.archivos];
  //   const archivo = archivos[index].archivo;

  //   archivos.splice(index, 1);
  //   let data = { ...this.state.data };
  //   data.archivos = archivos;
  //   this.setState({ data });
  //   try {
  //     await deleteFile(data._id, archivo);
  //   } catch (ex) {
  //     if (ex.response && ex.response.status === 404) toastError("Ese archivo ya fue eliminado");
  //     let data = { ...this.state.data };
  //     data.archivos = originalFiles;
  //     this.setState({ data });
  //   }
  // };

  // agregarSocio = async () => {
  //   const persona = await getPersona(this.state.personaId);
  //   const participacion = this.state.participacion;
  //   const socio = {
  //     persona: persona,
  //     participacion: participacion,
  //   };
  //   // const nombre = this.state.data.nombre;
  //   // const ubicacion = this.state.data.ubicacion;
  //   const data = { ...this.state.data };
  //   let socios = [...this.state.data.socios];
  //   socios.push(socio);
  //   data.socios = socios;
  //   this.setState({
  //     data,
  //     participacion: "",
  //     personaId: "",
  //     persona: "",
  //   });
  // };

  mapToViewModel = (proyecto) => {
    const { aportes } = proyecto;
    aportes.forEach((aporte) => {
      aporte.fecha = moment(aporte.fecha).format("YYYY-MM-DD");
      aporte.moneda = { value: aporte.moneda._id, label: aporte.moneda.simbolo };
    });
    return {
      _id: proyecto._id,
      nombre: proyecto.nombre,
      ubicacion: proyecto.ubicacion,
      // titular: proyecto.titular ? proyecto.titular._id : "",

      titular: proyecto.titular
        ? {
            value: proyecto.titular._id,
            label: proyecto.titular.apellido + " " + proyecto.titular.nombre,
          }
        : { label: "", value: "" },
      partida: proyecto.partida,
      matricula: proyecto.matricula,
      nomenclatura: proyecto.nomenclatura,
      notas: proyecto.notas,
      archivos: proyecto.archivos,
      subproyectos: proyecto.subproyectos ? proyecto.subproyectos : [],
      presupuestos: proyecto.presupuestos,
      socios: proyecto.socios,
      aportes: aportes,
    };
  };

  prepareData = () => {
    const { data } = this.state;
    if (data.titular.value) {
      this.setState({ titular: data.titular });
      data.titular = data.titular.value;
    }
    if (data.aportes) {
      data.aportes.forEach((aporte) => {
        aporte.monto = formattedToFloat(aporte.monto);
        if (aporte.moneda.value) aporte.moneda = aporte.moneda.value;
      });
    }
    this.setState({ data });
  };

  doSubmit = async () => {
    try {
      const newId = await saveProyecto(this.state.data);
      if (this.state.data._id) this.props.history.push("/proyectos");
      else {
        if (newId) {
          const data = { ...this.state.data };
          this.setState({
            data: { ...data, _id: newId },
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export default withRouter(ProyectosForm);

import React, { Component } from "react";
import { getProyecto, getProyectosSelect } from "../../services/proyectosService";
import { dateFormatter } from "../utils/utils";

import ContentHeader from "../common/contentHeader";
import Combo from "../common/combo.jsx";
import moment from "moment";
import jwtDecode from "jwt-decode";
import { getUser } from "../../services/userService";
import { getMovimientosPresupuestos } from "../../services/cajasDomoService";
import NumberFormat from "react-number-format";

export default class SaldosPresupuestos extends Component {
  title = "Saldos Presupuestos";

  breadcrumbs = [
    {
      title: "Inicio",
      link: "/main",
    },
    {
      title: "Proyectos",
      link: "/proyectos",
    },
    {
      title: "Cajas",
    },
  ];

  customStyles = {
    groupHeading: (provided, state) => ({
      ...provided,
      fontWeight: "bold",
      color: "black",
      padding: 10,
    }),
  };

  componentRef = "";

  state = {
    isAdmin: false,
    selectedProyecto: {
      value: "",
      label: "",
    },
    selectedPresupuesto: {
      value: "",
      label: "",
    },
    proyectos: [],
    presupuestos: [],
    registrosSinDocumento: [],
    registrosDocumentados: [],
    totalSinDocumento: "",
    totalDocumentado: "",
  };

  componentDidMount = async () => {
    const { onLoading, onDoneLoading } = this.props;
    onLoading();

    const jwt = localStorage.getItem("token");
    const decoded = jwt ? jwtDecode(jwt) : null;
    if (!decoded) return this.props.history.replace("/login");
    const user = await getUser(decoded.user._id);
    const proyectos = await getProyectosSelect();
    this.setState({ isAdmin: user.profile.isAdmin, proyectos });
    onDoneLoading();
  };

  handleChangeProyecto = async (selectedOption) => {
    const proyecto = await getProyecto(selectedOption.value);
    let pre = proyecto.presupuestos.filter((m) => m.estado === "Aceptado");
    let presupuestos = [];
    pre.forEach((presu) => {
      const insert = {
        value: presu._id,
        label: presu.descripcion,
        totalSinDocumento: presu.totalsindocumento,
        totalDocumentado: presu.totaldocumentado,
      };
      presupuestos.push(insert);
    });
    this.setState({
      presupuestos,
      selectedProyecto: selectedOption,
      selectedPresupuesto: { value: "", label: "" },
      totalSinDocumento: "",
      totalDocumentado: "",
      registrosSinDocumento: [],
      registrosDocumentados: [],
    });
  };

  handleChangePresupuesto = async (selectedOption) => {
    const registrosSinDocumento = await getMovimientosPresupuestos(selectedOption.value, false);
    const registrosDocumentados = await getMovimientosPresupuestos(selectedOption.value, true);
    this.setState({
      selectedPresupuesto: selectedOption,
      totalDocumentado: selectedOption.totalDocumentado,
      totalSinDocumento: selectedOption.totalSinDocumento,
      registrosSinDocumento,
      registrosDocumentados,
    });
  };

  render() {
    const { isAdmin, proyectos, presupuestos, selectedPresupuesto, selectedProyecto, totalSinDocumento, totalDocumentado, registrosSinDocumento, registrosDocumentados } = this.state;
    let saldoSinDocumento = 0;
    let saldoDocumentado = 0;
    return (
      <div>
        <ContentHeader title={this.title} breadcrumbs={this.breadcrumbs} />

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row ">
              {/* left column */}
              <div className="col-12">
                <div className="card card-secondary">
                  <div className="card-header">
                    <div className="row align-bottom">
                      <div className="col-6">
                        <Combo options={proyectos} value={selectedProyecto} className="form-select" onChange={this.handleChangeProyecto} />
                      </div>
                      <div className="col-6">
                        <Combo options={presupuestos} value={selectedPresupuesto} className="form-select" onChange={this.handleChangePresupuesto} />
                      </div>
                    </div>
                  </div>
                  <div className="card-body pad">
                    {this.state.selectedPresupuesto.value !== "" && (
                      <React.Fragment>
                        <div className="row">
                          <p>
                            {" "}
                            <h5>Sin Documento</h5>
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-12 text-right">
                            <p>
                              <h6>
                                Total Presupuestado: <NumberFormat value={totalSinDocumento} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} fixedDecimalScale={true} />
                              </h6>
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-3"></div>
                          <div className="col-9">
                            <table className="table table-sm  table-striped">
                              <thead>
                                <tr>
                                  <th width="10%">Fecha</th>
                                  <th width="15%">Detalle</th>
                                  <th className="text-center" width="10%">
                                    Ingreso
                                  </th>
                                  <th className="text-center" width="10%">
                                    Egreso
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {registrosSinDocumento.map((caja, index) => {
                                  saldoSinDocumento += caja.ingreso - caja.egreso;
                                  return (
                                    <React.Fragment>
                                      <tr key={`caja${index}`}>
                                        <td>{dateFormatter(caja.fecha)}</td>
                                        <td>{caja.detalle}</td>
                                        <td className="text-right">{caja.ingreso > 0 && <NumberFormat value={caja.ingreso} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} fixedDecimalScale={true} />}</td>
                                        <td className="text-right">{caja.egreso > 0 && <NumberFormat value={caja.egreso} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} fixedDecimalScale={true} />}</td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="row">
                          <p>
                            {" "}
                            <h5>Documentado</h5>
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-12 text-right">
                            <p>
                              <h6>
                                Total Presupuestado: <NumberFormat value={totalDocumentado} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} fixedDecimalScale={true} />
                              </h6>
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-3"></div>
                          <div className="col-9">
                            <table className="table table-sm  table-striped">
                              <thead>
                                <tr>
                                  <th width="10%">Fecha</th>
                                  <th width="15%">Detalle</th>
                                  <th className="text-center" width="10%">
                                    Ingreso
                                  </th>
                                  <th className="text-center" width="10%">
                                    Egreso
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {registrosDocumentados.map((caja, index) => {
                                  saldoDocumentado += caja.ingreso - caja.egreso;
                                  return (
                                    <React.Fragment>
                                      <tr key={`caja${index}`}>
                                        <td>{dateFormatter(caja.fecha)}</td>
                                        <td>{caja.detalle}</td>
                                        <td className="text-right">{caja.ingreso > 0 && <NumberFormat value={caja.ingreso} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} fixedDecimalScale={true} />}</td>
                                        <td className="text-right">{caja.egreso > 0 && <NumberFormat value={caja.egreso} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} fixedDecimalScale={true} />}</td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="row"></div>
                        <div className="row"></div>
                        <div className="row">
                          <h5>
                            Saldo:{" "}
                            <NumberFormat
                              value={parseFloat(totalDocumentado) + parseFloat(totalSinDocumento) + parseFloat(saldoDocumentado) + parseFloat(saldoSinDocumento)}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </h5>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
              {/* /.row */}
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
      </div>
    );
  }
}
